<template>
    <div>
        <Header title="顧客資料查詢">
            <template #right>
                <svg-icon
                    icon-class="scan"
                    style="font-size: 14px;"
                    @click="goScan"
                />
            </template>
        </Header>
        <div class="search">
            <!-- <van-field v-model="value" label="" placeholder="請輸入顧客編號" /> -->
            <el-input v-model="value" placeholder="請輸入顧客編號" style="width: 50%;margin-bottom: 48px;"></el-input>
            <el-button type="primary" style="width: 30%;" @click="goSearch" :loading="loading">{{loading ? '查詢中' : '確定'}}</el-button>
        </div>
        <ScanComponent ref="scanComponent" @onDecode="onDecode"/>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import ScanComponent from '@/components/ScanComponent.vue'
export default {
     components:{Header,ScanComponent},
    data(){
        return {
            // value:'BHC1234',
            value:'',
            error:'',
            camera:'off',
            showCamera:false,
            result:'',
            openCamera:false,
            loading:false
        }
    },
    methods:{
        goSearch(){
            this.loading = true;
            this.$api.customer.getCustomerInfoByCode({code:this.value}).then(res => {
                this.showCamera = false;
                this.loading = false;
                let { code, data, msg } = res;
                if(code === 0 && data.customer) {
                    this.$router.push(`/customerDetail?code=${this.value}`);
                } else {
                    this.$message.warning(msg ?? '暫無數據')
                }
            })
        },
        goScan(){
            this.$refs.scanComponent.goScan();
        },
        onDecode(result){
            this.value = result;
            this.goSearch();
        }
    }
}
</script>

<style lang="scss" scoped>
.scan {
    color: #0052D9;
    width: 14px;
    height: 14px;
}

.search {
    background-color: #fff;
    padding: 100px 48px 48px 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
