<template>
    <div
        class="empty"
    >
        <img
            :width="''+imagew"
            :src="imageUrl"
            alt=""
        >
        <slot>
            <div
                style="margin-top: 30px ;margin-bottom: 30px;color: #999999;font-size: 14px;line-height: 14px;"
            >
                {{ emptyText }}
            </div>
        </slot>
    </div>
</template>

<script>
import nodata from '@/assets/no-data.png';
export default {
    name: 'Empty',
    props: {
        emptyText: {
            type: String,
            default: '暫無數據'
        },
        imagew: {
            type: [Number, String],
            default: 100
        },
        imgurl: {
            type: String,
            default: ''
        }
    },
    computed: {
        imageUrl() {
            let url = this.imgurl || nodata;
            return url;
        }
    }
};
</script>

<style lang="scss" scoped>
.empty {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
</style>
