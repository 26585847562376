const files = require.context('@/api/module', true, /(.*?).js$/);
// console.log(files,'files')
function createModules() {
    let modules = {};
    files.keys().forEach((key) => {
        const module = files(key).default || files(key);
        let [, name] = key.match(/^.+\/(\w+\.\w+)/i);
        name = name.split('.')[0];
        modules[name] = module;
    });
    return modules;
}
// console.log('===>api<===', module);
export default { install(Vue) {
    Vue.prototype.$api = createModules(files);
}};