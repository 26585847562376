import Vue from 'vue';
import SvgIcon from '@/components/svgIcon/index.vue'; // svg组件
// import store from '@/store';
// 注册为全局组件
Vue.component('svg-icon', SvgIcon);
const req = require.context('./svgIcon', false, /\.svg$/);
const requireAll = requireContext => requireContext.keys().map(requireContext);
// 将Icon写入缓存
// if(process.env.NODE_ENV == 'development'){
//     let arr = JSON.parse(JSON.stringify(requireAll(req)));
//     let list = [];
//     arr.forEach(el => {
//         if(el.default.id) {
//             el.default.id = el.default.id.replace(/icon-/, '');
//         }
//         list.push(el.default.id)
//     });
//     store.commit("setIconList", list)
// }
requireAll(req);
// console.log(requireAll(req), 'requireAll(req)');

