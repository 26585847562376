<template>
    <!-- <van-popup v-model="isScaning" position="bottom" :style="{ height: '100%' }"> -->
    <van-overlay :show="isScaning" >
        <div class="wrapper">
            <svg-icon
                icon-class="delete-icon"
                @click="stopScan"
                class="delete-icon"
            />
            <div class="qrcode">
                <div id="reader"></div>
            </div>
            <!-- <div id="reader" v-if="isScaning"></div> -->
        </div>
    <!-- </van-popup> -->
    </van-overlay>
</template>

<script>
import { Overlay } from 'vant';
import { Html5Qrcode } from 'html5-qrcode';
export default {
    data(){
        return {
            html5Qrcode: null,
		    isScaning: false,
        }
    },
    mounted(){},
    methods:{
        goScan() {
            this.isScaning = true;
            Html5Qrcode.getCameras().then(devices => {
                if (devices && devices.length) {
                    this.html5Qrcode = new Html5Qrcode('reader');
                    this.html5Qrcode.start({
                        facingMode: 'environment',
                    }, {
                        fps: 4,
                        qrbox: { width: 250, height: 250 },
                        aspectRatio: 1.777778  // 1  1.777778  1.333334
                        // canvasHeight:500
                        // aspectRatio: 0.6
                    }, (decodeText, decodeResult) => {
                        // alert(JSON.stringify(decodeText));
                        // console.log(decodeText)
                        if (decodeText) {
                            this.stopScan();
                            this.isScaning = false;
                            this.$emit('onDecode',decodeText);
                        }
                    }, (err) => {
                        // alert(JSON.stringify(err));
                        console.log(err)
                    });
                }
            });
        },
        stopScan() {
            this.isScaning = false;
            this.html5Qrcode.stop();
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    position: relative;
    height: 100%;

    .delete-icon {
        font-size: 26px;
        position: absolute;
        top: 28px;
        left: 28px;
        color:#fff;
        z-index:99999;
    }
}
</style>

<style lang="scss">
.qrcode {
    display: flex;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: #333;
}

#reader {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    pointer-events: none;
}
#reader video {
    height: 100%;
    width: 100%;
    display: block;
    // object-fit: cover;
}
</style>
