import http from '@/utils/httpRequest';

export default {
    async login(params) {
        let {data} = await http({
            url: '/core/user/login.json',
            method: 'post',
            data: params,
        });
        return data;
    }

};