import http from '@/utils/httpRequest';

export default {
    async getCoreDict(params) {
        let {data} = await http({
            url: '/core/dict/view.json',
            method: 'get',
            data: params,
        });
        return data;
    }
}