import axios from 'axios';
import $http from './httpRequest';

export function ossUploadFile(file) {
    return new Promise((resolve) => {
        $http({
            url: '/aliyun/oss/policy',
            method: 'get'
        }).then(res => {
            if(res.data.code == 0 && res.data.data) {
                let {data} = res.data;
                let fromData = new FormData();
                let fileName = `${Date.now()}.${file.type.split("/")[1]}`
                let pathName = `${data.dir}${fileName}`;//相对路径
                fromData.append("key", pathName);//key 唯一值  即相对路径
                fromData.append("policy", data.policy);//服务器返回的policy
                fromData.append("OSSAccessKeyId", data.accessKeyId);//服务器返回的accessId
                fromData.append("success_action_status", "200");//定义成功为200
                fromData.append("signature", data.signature);//服务器返回的signature
                fromData.append("name", fileName);//文件名
                fromData.append("file", file, fileName);//文件对象
                axios({
                    url: data.host,
                    method: 'post',
                    data: fromData,
                    headers: {'Content-Type': 'multipart/form-data'}
                }).then(result => {
                    console.log(result,'result')
                    if(result.status == 200) {
                        let imgUrl = `${data.host}/${pathName}`;
                        console.log(imgUrl,'imgUrl');
                        resolve(imgUrl);
                    }
                })
            }
        }).catch(err => {
            console.log(err);
        });
    })
}

let $utils = {
    ossUploadFile,
    install(Vue) {
        Vue.prototype.$utils = $utils;
    }
}

function getUtils() {
    return $utils;
}
export { getUtils };