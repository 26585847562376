<template>
    <div class="add-photography">
        <div class="detail-info container-bottom">
            <div class="info-item flex-2">
                <span class="label">顧客編號</span>
                <span class="value">{{ form?.customer?.customerId }}</span>
            </div>
            <div class="info-item flex-2">
                <span class="label">姓氏</span>
                <span class="value">{{ form?.customer?.familyNames }}</span>
            </div>
            <div class="info-item flex-1">
                <span class="label" style="width: 17.5%;">攝影套餐</span>
                <span class="value">{{ form?.orderInfo?.shootingContent }}</span>
            </div>
            <div class="info-item flex-2">
                <span class="label">試衫時間</span>
                <span class="value">{{ formatValue(form?.orderInfo?.tryDateReal, 'yyyy-MM-dd HH:mm') }}</span>
            </div>
            <div class="info-item flex-2">
                <span class="label">試衫同事</span>
                <span contenteditable="true" class="value"  @blur="(e) => {handleBlur(e.target.innerText, 'shootingInfo', 'tryColleague')}">
                    {{ form?.shootingInfo?.tryColleague }}
                </span>
            </div>
            <div class="info-item flex-2">
                <span class="label">拍攝時間</span>
                <span class="value">{{ formatValue(form?.orderInfo?.shootingDateReal, 'yyyy-MM-dd HH:mm') }}</span>
            </div>
            <div class="info-item flex-2">
                <span class="label">拍攝景點</span>
                <span contenteditable="true" class="value"  @blur="(e) => {handleBlur(e.target.innerText, 'shootingInfo', 'shootingSite')}">
                    {{ form?.shootingInfo?.shootingSite }}
                </span>
            </div>
            <div class="info-item flex-2">
                <span class="label">澳門景點</span>
                <span contenteditable="true" class="value"  @blur="(e) => {handleBlur(e.target.innerText, 'shootingInfo', 'macaoSite')}">
                    {{ form?.shootingInfo?.macaoSite }}
                </span>
            </div>
            <div class="info-item flex-2">
            </div>
<!--            <div :class="{'info-item':true,'flex-3':index <= 2,'flex-2':index > 2}" v-for="(item,index) in baseInfo" :key="index">-->
<!--                <span class="label">{{ item.label }}</span>-->
<!--                <span class="value">-->
<!--                    <el-date-picker-->
<!--                        v-model="form[item.key1][item.key2]"-->
<!--                        type="date"-->
<!--                        placeholder=""-->
<!--                        v-if="item.type == 'date'"-->
<!--                        :editable="false"-->
<!--                        value-format="yyyy-MM-dd"-->
<!--                        prefix-icon=""-->
<!--                        @change="v => {changeDate(v,item.key1,item.key2)}"-->
<!--                        clear-icon="el-icon-date-clear"-->
<!--                    >-->
<!--                    </el-date-picker>-->

<!--                    <el-time-picker-->
<!--                            v-model="form[item.key1][item.key2]"-->
<!--                            :picker-options="{selectableRange: '00:00:00 - 23:59:59'}"-->
<!--                            placeholder=""-->
<!--                            prefix-icon=""-->
<!--                            :format="item.format"-->
<!--                            :value-format="item.format"-->
<!--                            :editable="false"-->
<!--                            :clearable="true"-->
<!--                            v-else-if="item.type == 'time'"-->
<!--                            @change="v => {changeTime(v,item.key1,item.key2)}"-->
<!--                    >-->
<!--                    </el-time-picker>-->

<!--                    <el-select-->
<!--                            v-model="form[item.key1][item.key2]"-->
<!--                            v-else-if="item.type === 'select'"-->
<!--                            placeholder="請選擇"-->
<!--                            style="width: 100%;"-->
<!--                            filterable-->
<!--                            allow-create-->
<!--                            @change="(e) => {handleBlur(e, item.key1, item.key2)}"-->
<!--                    >-->
<!--                        <el-option-->
<!--                                v-for="i in selectOptions[item.selectOptionType]"-->
<!--                                :key="i.name"-->
<!--                                :label="i.name"-->
<!--                                :value="i.value"-->
<!--                        >-->
<!--                        </el-option>-->
<!--                    </el-select>-->

<!--                    <span-->
<!--                        contenteditable="true"-->
<!--                        v-else-->
<!--                        style="width: 100%;"-->
<!--                        @blur="(e) => {handleBlur(e.target.innerText,item.key1,item.key2)}"-->
<!--                    >-->
<!--                        {{ form[item.key1][item.key2] }}-->
<!--                    </span>-->
<!--                </span>-->
<!--            </div>-->

            <table border="1" cellpadding="10" cellspacing="0" style="margin-right: 16px;" class="table1">
                <tr>
                    <th style="width: 19%;" rowspan="4">新娘</th>
                    <th style="width: 27%;">婚紗</th>
                    <th style="width: 27%;">晚裝</th>
                    <th style="width: 27%;">其它</th>
                </tr>
                <tr style="height: 100px;">
                    <td @click="goSearch('bride','wWeddingDress')">
                        <p v-for="(item,index) in clothInfo.bride.wWeddingDress" :key="index" class="clothing">
                            {{ item.code }}
                            <svg-icon
                                icon-class="delete"
                                style="font-size: 10px;margin-left: 8px;"
                                @click.stop="handleDelete('bride','wWeddingDress',item,index)"
                            />
                        </p>
                    </td>
                    <td @click="goSearch('bride','wEveningDress')">
                        <p v-for="(item,index) in clothInfo.bride.wEveningDress" :key="index" class="clothing">
                            {{ item.code }}
                            <svg-icon
                                icon-class="delete"
                                style="font-size: 10px;margin-left: 8px;"
                                @click.stop="handleDelete('bride','wEveningDress',item,index)"
                            />
                        </p>
                    </td>
                    <td @click="goSearch('bride','wOthers')">
                        <p v-for="(item,index) in clothInfo.bride.wOthers" :key="index" class="clothing">
                            {{ item.code }}
                            <svg-icon
                                icon-class="delete"
                                style="font-size: 10px;margin-left: 8px;"
                                @click.stop="handleDelete('bride','wOthers',item,index)"
                            />
                        </p>
                    </td>
                </tr>
            </table>

            <table border="1" cellpadding="10" cellspacing="0" class="table1">
                <tr>
                    <th style="width: 19%;" rowspan="4">新郎</th>
                    <th style="width: 27%;">男禮</th>
                    <th style="width: 27%;">褲</th>
                    <th style="width: 27%;">其他</th>
                </tr>
                <tr style="height: 100px;">
                    <td @click="goSearch('groom','mFormalDress')">
                        <p v-for="(item,index) in clothInfo.groom.mFormalDress" :key="index" class="clothing">
                            {{ item.code }}
                            <svg-icon
                                icon-class="delete"
                                style="font-size: 10px;margin-left: 8px;"
                                @click.stop="handleDelete('groom','mFormalDress',item,index)"
                            />
                        </p>
                    </td>
                    <td @click="goSearch('groom','mPants')">
                        <p v-for="(item,index) in clothInfo.groom.mPants" :key="index" class="clothing">
                            {{ item.code }}
                            <svg-icon
                                icon-class="delete"
                                style="font-size: 10px;margin-left: 8px;"
                                @click.stop="handleDelete('groom','mPants',item,index)"
                            />
                        </p>
                    </td>
                    <td @click="goSearch('groom','mOthers')">
                        <p v-for="(item,index) in clothInfo.groom.mOthers" :key="index" class="clothing">
                            {{ item.code }}
                            <svg-icon
                                icon-class="delete"
                                style="font-size: 10px;margin-left: 8px;"
                                @click.stop="handleDelete('groom','mOthers',item,index)"
                            />
                        </p>
                    </td>
                </tr>
            </table>

            <div class="flex" style="width: 100%;margin-right: 8px;margin: 24px 0;">
                <span style="display: inline-block;width: 60px;line-height: 117px;">備註：</span>
                <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="請輸入"
                    v-model="form.shootingInfo.lastRemarks"
                    style="width: 100%;"
                    @blur="handleBlur(form.shootingInfo.lastRemarks,'shootingInfo','lastRemarks')"
                >
                </el-input>
            </div>

            <table border="1" cellpadding="10" cellspacing="0" class="table2">
                <tr>
                    <th colspan="8">新娘</th>
                </tr>
                <tr>
                    <td style="width: 10%;">上圍</td>
                    <td style="width: 15%; max-width: 15%" contenteditable="true" @blur="(e) => {handleBlur(e.target.innerText,'shootingInfo','wBustline')}">{{ form.shootingInfo.wBustline }}</td>
                    <td style="width: 10%;">腰圍</td>
                    <td style="width: 15%; max-width: 15%" contenteditable="true" @blur="(e) => {handleBlur(e.target.innerText,'shootingInfo','wWaistline')}">{{ form.shootingInfo.wWaistline }}</td>
                    <td style="width: 10%;">下圍</td>
                    <td style="width: 15%; max-width: 15%" contenteditable="true" @blur="(e) => {handleBlur(e.target.innerText,'shootingInfo','wHipline')}">{{ form.shootingInfo.wHipline }}</td>
                    <td style="width: 10%;">肚</td>
                    <td style="width: 15%; max-width: 15%" contenteditable="true" @blur="(e) => {handleBlur(e.target.innerText,'shootingInfo','wTummy')}">{{ form.shootingInfo.wTummy }}</td>
                </tr>
                <tr>
                    <td rowspan="2">澳門</td>
                    <td>婚紗</td>
                    <td style="width: 75%; max-width: 75%" colspan="6" contenteditable="true" @blur="(e) => {handleBlur(e.target.innerText,'shootingInfo','wWeddingDress6')}">{{ form.shootingInfo.wWeddingDress6 }}</td>
                </tr>
                <tr>
                    <td>晚裝</td>
                    <td style="width: 75%; max-width: 75%" colspan="6" contenteditable="true" @blur="(e) => {handleBlur(e.target.innerText,'shootingInfo','wEveningDress6')}">{{form.shootingInfo.wEveningDress6}}</td>
                </tr>
            </table>

            <table border="1" cellpadding="10" cellspacing="0" class="table2" style="margin-bottom: 0; border-bottom: 0;">
                <tr>
                    <th colspan="5">新郎</th>
                </tr>
                <tr>
                    <td style="width: 15%;">袖長</td>
                    <td style="width: 35%; max-width: 35%" colspan="1" contenteditable="true" @blur="(e) => {handleBlur(e.target.innerText,'shootingInfo','mSleeveLength')}">{{ form.shootingInfo.mSleeveLength }}</td>
                    <td style="width: 20%;">褲長</td>
                    <td style="width: 35%; max-width: 35%" colspan="3" contenteditable="true" @blur="(e) => {handleBlur(e.target.innerText,'shootingInfo','mPantsLength')}">{{ form.shootingInfo.mPantsLength }}</td>
                </tr>
            </table>
            <table border="1" cellpadding="10" cellspacing="0" class="table2">
                <tr>
                    <td style="width: 15%;" rowspan="2">澳門</td>
                    <td style="width: 15%;" rowspan="2">男禮</td>
                    <td style="width: 20%; max-width: 20%" contenteditable="true" @blur="(e) => {handleBlur(e.target.innerText,'shootingInfo','mFormalDress5')}">{{ form.shootingInfo.mFormalDress5 }}</td>
                    <td style="width: 20%; max-width: 20%" contenteditable="true" @blur="(e) => {handleBlur(e.target.innerText,'shootingInfo','mPants5')}">{{ form.shootingInfo.mPants5 }}</td>
                    <td style="width: 30%; max-width: 30%" rowspan="2" contenteditable="true" @blur="(e) => {handleBlur(e.target.innerText,'shootingInfo','mOthers6')}">{{ form.shootingInfo.mOthers6 }}</td>
                </tr>
                <tr>
                    <td contenteditable="true" @blur="(e) => {handleBlur(e.target.innerText,'shootingInfo','mFormalDress6')}">{{ form.shootingInfo.mFormalDress6 }}</td>
                    <td contenteditable="true" @blur="(e) => {handleBlur(e.target.innerText,'shootingInfo','mPants6')}">{{ form.shootingInfo.mPants6 }}</td>
                </tr>
            </table>

            <table border="1" cellpadding="10" cellspacing="0" class="table3">
                <tr>
                    <th style="width: 70px;">上傳圖片</th>
                    <th style="display: flex;align-items: center;">
                        <span class="img" v-for="(item,index) in form.orderImages" :key="index">
                            <img :src="item.image" @click="goPreview(item.image)">
                            <svg-icon
                                icon-class="delete-icon"
                                style="font-size: 14px;"
                                @click.stop="deleteImg(index)"
                                class="delete-icon"
                            />
                        </span>
                        <span class="add-btn">
                            <svg-icon
                                icon-class="add-icon"
                                style="font-size: 24px;"
                                @click.stop="uploadFile"
                            />
                        </span>
                    </th>
                </tr>
            </table>
        </div>
        <input
            v-show="false"
            type="file"
            id="fileInput"
            @change="handleFileChange"
            name="file"
            ref="file"
        />
        <ClothInfo ref="clothInfo" @submit="submitClothing"/>
    </div>
</template>

<script>
import ClothInfo from './ClothInfo.vue';
import { Dialog,ImagePreview  } from 'vant';
import axios from 'axios';
export default {
    components:{ClothInfo},
    props:{
        orderForm:{
            type:Object,
            default:() => {}
        }
    },
    data(){
        return {
            textarea:'',
            baseInfo:[
                // {label:'試衫日期',value:'',key1:'orderInfo',key2:'tryDateReal',type:'date'},
                // {label:'時間',value:'',key1:'shootingInfo',key2:'time', type: 'time', format: 'HH:mm'},
                // // {label:'時間',value:'',key1:'shootingInfo',key2:'time', type: 'select', selectOptionType: 'tryTime'},
                // {label:'試衫同事',value:'',key1:'shootingInfo',key2:'tryColleague'},
                // {label:'拍攝景點',value:'',key1:'shootingInfo',key2:'shootingSite'},
                // {label:'拍攝時間',value:'',key1:'orderInfo',key2:'shootingDateReal',type:'date'},
                // {label:'澳門景點',value:'',key1:'shootingInfo',key2:'macaoSite'},
                // {label:'拍攝時間',value:'',key1:'shootingInfo',key2:'macaoDate', type: 'time', format: 'HH:mm:ss'},
            ],
            clothInfo:{
                // 新娘
                bride:{
                    wWeddingDress:[],
                    wEveningDress:[],
                    wOthers:[]
                },
                // 新郎
                groom:{
                    mFormalDress:[],
                    mPants:[],
                    mOthers:[]
                }
            },
            field1:'',
            field2:'',
            form:{
                orderInfo: {},
                shootingInfo:{}
            },
            selectOptions: {
                tryTime: []
            }
        }
    },
    mounted(){
        this.getInfo();
        let coreDict = JSON.parse(localStorage.getItem('coreDict')) ?? [];
        this.selectOptions.tryTime = coreDict.filter(item => item.type === 'try_time');
    },
    methods:{
        getInfo(){
            console.info('orderForm', this.orderForm?.orderInfo?.['orderAId']);
            let orderAId = this.orderForm?.orderInfo?.['orderAId'];
            if (!orderAId) {
                return;
            }
            this.$api.customer.viewOrderA(orderAId).then(res => {
                if(res.code == 0 && res.data) {
                    this.form = res.data;
                    console.log(this.form,'this.form')
                    // this.clothInfo.bride.wWeddingDress = this.form.wWeddingDress || [];
                    // this.clothInfo.bride.wEveningDress = this.form.wEveningDress || [];
                    // this.clothInfo.bride.wOthers = this.form.wOthers || [];

                    // this.clothInfo.groom.mFormalDress = this.form.mFormalDress || [];
                    // this.clothInfo.groom.mPants = this.form.mPants || [];
                    // this.clothInfo.groom.mOthers = this.form.mOthers || [];

                    // 从shootingInfo拿衣服数据，和管理后台统一格式
                    const { shootingInfo = {} } = this.form;
                    const checkString = (value) => {
                        return value !== undefined && value !== null && value !== '';
                    }
                    const maxClothingIndex = 5;
                    const wWeddingDress = [];
                    const wEveningDress = [];
                    const wOthers = [];
                    const mFormalDress = [];
                    const mPants = [];
                    const mOthers = [];
                    for (let i = 1; i <= maxClothingIndex; i++) {
                        if (checkString(shootingInfo['wWeddingDress' + i])) {
                            wWeddingDress.push({
                                code: shootingInfo['wWeddingDress' + i]
                            });
                        }
                        if (checkString(shootingInfo['wEveningDress' + i])) {
                            wEveningDress.push({
                                code: shootingInfo['wEveningDress' + i]
                            });
                        }
                        if (checkString(shootingInfo['wOthers' + i])) {
                            wOthers.push({
                                code: shootingInfo['wOthers' + i]
                            });
                        }
                        if (checkString(shootingInfo['mFormalDress' + i])) {
                            mFormalDress.push({
                                code: shootingInfo['mFormalDress' + i]
                            });
                        }
                        if (checkString(shootingInfo['mPants' + i])) {
                            mPants.push({
                                code: shootingInfo['mPants' + i]
                            });
                        }
                        if (checkString(shootingInfo['mOthers' + i])) {
                            mOthers.push({
                                code: shootingInfo['mOthers' + i]
                            });
                        }
                    }
                    this.clothInfo.bride.wWeddingDress = wWeddingDress;
                    this.clothInfo.bride.wEveningDress = wEveningDress;
                    this.clothInfo.bride.wOthers = wOthers;

                    this.clothInfo.groom.mFormalDress = mFormalDress;
                    this.clothInfo.groom.mPants = mPants;
                    this.clothInfo.groom.mOthers = mOthers;
                }
            })
        },
        saveInfo(){
            this.$api.customer.saveOrderA(this.form).then(res => {
                if(res.code === 0) {
                    this.getInfo();
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        goSearch(field1,field2){
            this.$refs.clothInfo.open("A",this.form.orderInfo.orderAId,field2);
            this.field1 = field1;
            this.field2 = field2;
        },
        submitClothing(code){
            console.log("clothing submit code", code);
            // this.getInfo();
            // this.clothInfo[this.field1][this.field2].push(code);
            try {
                this.clothInfo[this.field1][this.field2].push({code});
                const len = this.clothInfo[this.field1][this.field2].length;
                this.$set(this.form.shootingInfo, this.field2 + len, code);
                this.saveInfo();
            } catch(e) {
                console.log(e)
            }
        },
        handleDelete(field1,field2,item,index){
            Dialog.confirm({
                title: '',
                message: `是否確認刪除"${item.code}"該服裝`,
                confirmButtonColor:"#0052D9",
                confirmButtonText: '確定',
                cancelButtonColor:"#666",
                cancelButtonText: '取消'
            }).then(() => {
                    // on confirm
                    let form = {
                        bizClothes:item.id,
                        dressAs:field2,
                        orderCode:this.form.orderInfo.orderAId,
                        orderType:'A'
                    }
                    this.$api.customer.removeClothesBookingInfo(form).then(res => {
                        if(res.code == 0) {
                            this.clothInfo[field1][field2].splice(index,1);
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                })
                .catch(() => {
                    // on cancel
                });
        },
        uploadFile(){
            this.$refs.file.click()
        },
        handleFileChange(e){
            let file = e.target.files[0];
            console.log(file,"單文件流文件流");
            var regex = /^(?:jpg|jpeg|png|bmp)$/i;
            if(!regex.test(file.type.split("/")[1])) {
                this.$message.error('請上傳圖片格式');
                return
            }
            this.$utils.ossUploadFile(file).then(res => {
                console.log(res,'rrrrrrrrrrr');
                this.form.orderImages.push({image:res,id:''});
                this.saveInfo();
            })
        },
        goPreview(url){
            ImagePreview([url]);
            // if(this.detailInfo.image)  {
            //     ImagePreview([this.detailInfo.image]);
            // } else {
            //     Toast('暫無此服裝圖片');
            // }
        },
        deleteImg(index){
            Dialog.confirm({
                title: '',
                message: `是否確認刪除該圖片`,
                confirmButtonColor:"#0052D9",
                confirmButtonText: '確定',
                cancelButtonColor:"#666",
                cancelButtonText: '取消'
            }).then(() => {
                    // on confirm
                    this.form.orderImages.splice(index,1);
                    this.saveInfo();
                })
                .catch(() => {
                    // on cancel
                });
        },
        changeDate(e,key1,key2){
            let dateFormat = null;
            if (e) {
                if (typeof e === 'object') {
                    dateFormat = e.toLocaleString().replaceAll("/", "-");
                } else {
                    dateFormat = e.length === 10 ? e += " 00:00:00" : e;
                }
            }
            this.form[key1][key2] = dateFormat;
            this.saveInfo();
        },
        changeTime(e,key1,key2){
            this.form[key1][key2] = e;
            this.saveInfo();
        },
        handleBlur(e,key1,key2){
            // console.log('失焦了。。。',e,key1,key2);
            this.form[key1][key2] = e;
            this.saveInfo();
        },
        formatValue(value, format) {
            if (!value) {
                return value;
            }
            if (format === 'yyyy-MM-dd HH:mm' && value.split(':').length === 3){
                return value.substring(0, 16);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.add-photography {
    background-color: #fff;
    margin-top: 24px;
    padding: 24px;

    .detail-info {
        display: flex;
        flex-wrap: wrap;
        .info-item {
            // width: calc(50% - 8px);
            display: flex;
            margin-bottom: 16px;
            // margin-right: 16px;
            span {
                display: inline-block;
                // height: 30px;
                min-height: 30px;
                line-height: 1.5;
                font-size: 14px;
                border: 1px solid #e5e5e5;
            }
            .label {
                width: 35%;
                border-right: none;
                color: #666;
                background-color: #F8F8F8;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .value {
                flex: 1;
                color: #333;
                word-break: break-all;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .flex-1 {
            width: calc(100% - 8px);
            margin-right: 8px;
        }
        .flex-2 {
            width: calc(50% - 8px);
            margin-right: 8px;
        }
        .flex-3{
            width: calc(33% - 6px);
            margin-right: 8px;
        }

        div:nth-child(3) {
            margin-right: 0;
        }
    }

    .table1 {
        border-color: #eee;
        width: calc(50% - 16px);
        p {
            margin: 0 0 12px 0;
        }
    }

    .table2 {
        border-color: #eee;
        width: 100%;
        margin-bottom: 24px;
        p {
            margin: 0 0 12px 0;
        }
        tr,td {
            word-break: break-all;
        }
    }

    .table3 {
        border-color: #eee;
        width: 100%;
        th {
            height: 100px;
        }
        p {
            margin: 0 0 12px 0;
        }
        .add-btn {
            display: inline-block;
            width: 80px;
            height: 80px;
            line-height: 95px;
            text-align: center;
            border: 1px solid #e5e5e5;
            margin-right: 12px;
        }
        .img {
            position: relative;
            display: inline-block;
            margin-right: 12px;
            img {
                width: 80px;
                height: 80px;
            }
            .delete-icon {
                position: absolute;
                top: -7px;
                right: -7px;
                background-color: white;
                border-radius: 11px;
            }
        }
    }

    .clothing {
        display: flex;
        align-items: center;
        font-size: 12px;
        white-space: nowrap;
    }

    .flex {
        display: flex;
        align-content: center;
    }
}
.container-bottom {
    margin-bottom: 100px;
}

::v-deep .el-date-editor {
    width: 100% !important;

    .el-input__inner {
        padding-left: 12px !important;
    }
}

::v-deep .el-input__inner {
    width: 100% !important;
    height: 32px;
    // border: none;
}
::v-deep .el-icon-date:before {
    display: none;
}
::v-deep .el-icon-time:before {
    display: none;
}
::v-deep .el-icon-circle-close {
    line-height: 30px;
}
::v-deep .el-input__inner {
    border-bottom: none;
}
::v-deep .el-date-editor  {
    height: 100%;
    .el-input__inner {
        height: 100%;
    }
}

::v-deep .el-icon-date-clear {
    display: none;
}

::v-deep .el-select {
    height: 100%;
    .el-input {
        height: 100%;
    }
    .el-input__inner {
        height: 100%;
    }
}
</style>
