<template>
    <div class="login">
        <!-- <svg-icon
            icon-class="delete-icon"
            style="font-size: 18px;position: absolute;top: 4px;left: 10px;color:#fff;z-index:999"
            @click="closeCamera"
            v-if="openCamera"
        /> -->
        <div class="login_content">
            <img src="../assets/login_logo.png" class="login_logo">
            <van-form label-width='50px' :show-error="false" @submit="onSubmit" ref="form">
                <van-field
                    v-model="form.code"
                    name="賬號"
                    label="賬號"
                    placeholder="請輸入賬號"
                />
                <van-field
                    v-model="form.password"
                    name="密碼"
                    label="密碼"
                    placeholder="請輸入密碼"
                    type="password"
                />
                <div style="margin: 16px;">
                    <van-button
                        round block
                        type="info"
                        native-type="submit"
                        :loading='loading'
                        loading-text='登錄中'
                    >
                        登錄
                    </van-button>
                </div>
            </van-form>
            <!-- <svg-icon
                icon-class="scan"
                style="font-size: 14px;"
                @click="scanCode"
            /> -->
        </div>
        <!-- <ScanComponent ref="scanComponent"  @onDecode="onDecode"/> -->
    </div>
</template>

<script>
import ScanComponent from '@/components/ScanComponent.vue'
export default {
    components:{ScanComponent},
    data(){
        return {
            form:{
                code: '',
                password: '',
            },
            loading:false,
        }
    },
    mounted(){

    },
    methods:{
        onSubmit(){
            this.loading = true;
            this.$api.login.login(this.form).then(res => {
                this.loading = false;
                if(res.code == 0 && res.data) {
                    localStorage.setItem('userInfo', JSON.stringify(res.data));
                    this.$router.push('/home');
                }
            })
        },
        scanCode(){
            this.$refs.scanComponent.goScan();
        },
        onDecode(value){
            alert(JSON.stringify(value));
        },
    }
}
</script>

<style lang="scss" scoped>
.login {
    position: relative;
    margin-top: 15vh;

    .login_content {
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .login_logo {
            width: 180px;
            height: 60px;
        }
    }

    button {
        border-radius: 4px;
        height: 36px;
        // width: 72px;
        // height: 36px;
    }
}

::v-deep .van-form {
    width: 50%;
}
</style>
