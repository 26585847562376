<template>
    <div class="header">
        <img src="../assets/login_logo.png" class="login_logo" @click="goHome">
        <div>
            <van-popover
                v-model="showPopover"
                trigger="click"
                :actions="actions"
                @select="onSelect"
            >
                <template #reference>
                    <span class="name">
                        {{userInfo.user.name}}
                        <img src="../assets/down_icon.png" style="width: 14px;height: 14px;vertical-align: text-top;">
                    </span>
                </template>
            </van-popover>
            <span class="time">{{currentTime }}</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showPopover:false,
            actions: [{ text: '退出' }],
            userInfo:{},
            timeIntervalId:null,
            currentTime:'',
        }
    },
    created(){
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    },
    mounted() {
        this.getTime();
        this.timeIntervalId = setInterval(() => {this.getTime()}, 1000); // 每隔1秒調用一次currentTime計算屬性
    },
    beforeDestroy() {
        clearInterval(this.timeIntervalId); // 組件被銷毀之前清除定時器
    },
    methods:{
        onSelect(action){
            console.log(action,'action')
            if(action.text == '退出') {
                this.$router.replace(`/login`);
            }
        },
        getTime(){
            const date = new Date(); // 創建一個日期對象
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const hours = String(date.getHours()).padStart(2, '0'); // 格式化小時部分
            const minutes = String(date.getMinutes()).padStart(2, '0'); // 格式化分鐘部分
            this.currentTime = `${year}/${month}/${day} ${hours}:${minutes}`; // 返回格式化后的時間字符串
        },
        goHome(){
            console.log(this.$route,'ddddddddddddddd');
            if(this.$route.name !== 'home') {
                this.$router.push('/home');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px;
    background: #F5F5F5;

    img {
        width: 180px;
        height: 60px;
    }

    div {
        .name {
            margin-right: 24px;
        }
        span {
            font-size: 14px;
            color: #333;
        }
    }
}
</style>
