<template>
    <div class="photography">
        <div v-if="$parent.pageType == 'photography'">

            <div class="container">
                <div class="title">訂單簡介
                    <svg-icon
                        icon-class="edit-icon"
                        style="font-size: 11px;float: right;"
                        @click.stop="handleEdit()"
                    />
                </div>
                <div class="detail-info">
                    <div class="info-item" v-for="(item,index) in detailInfo" :key="index">
                        <span class="label">{{ item.label }}</span>
                        <span :class="{'value':true,'detail-info-value':true,'show-all':item.showAll}">
                            <span class="text" :id="item.key">{{ item.value }}</span>
                            <span v-show="item.isExceed" class="more" @click="handleShowAll(index,item.key)">{{ item.showAll ? '收起' : '展開' }}</span>
                        </span>
                        <!-- <span v-show="item.isExceed" class="more" @click="handleShowAll(index,item.key)">{{ item.showAll ? '收起' : '展開' }}</span> -->
                    </div>
                </div>
            </div>

            <div class="container container-bottom">
                <div class="title">預約信息</div>
                <div class="reservation">
                    <div class="info-item" style="width: 20%">
                        <span class="value"></span>
                    </div>
                    <div class="info-item" style="width: 30%">
                        <span class="value">試衫時間</span>
                    </div>
                    <div class="info-item" style="width: 30%">
                        <span class="value">攝影時間</span>
                    </div>
                    <div class="info-item" style="width: 20%">
                        <span class="value"></span>
                    </div>
                    <div class="info-item" style="width: 20%">
                        <span class="value">預約時間</span>
                    </div>
                    <div class="info-item" style="width: 30%">
                        <el-date-picker
                                v-model="orderForm.orderInfo.tryDateReal"
                                type="datetime"
                                placeholder=""
                                :editable="false"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                prefix-icon=""
                                @change="v => {changeValue(orderForm.orderInfo, 'tryDateReal', v)}"
                                clear-icon="el-icon-date-clear"
                                class="reservation-date-picker"
                        >
                        </el-date-picker>
                    </div>
                    <div class="info-item" style="width: 30%">
                        <el-date-picker
                                v-model="orderForm.orderInfo.shootingDateReal"
                                type="datetime"
                                placeholder=""
                                :editable="false"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                prefix-icon=""
                                @change="v => {changeValue(orderForm.orderInfo, 'shootingDateReal', v)}"
                                clear-icon="el-icon-date-clear"
                                class="reservation-date-picker"
                        >
                        </el-date-picker>
                    </div>
                    <div class="info-item" style="width: 20%">
                        <span class="value" style="color: #0052D9" @click="addInfo(true)">編輯</span>
                    </div>
                </div>
            </div>
        </div>

        <AddPhotography
            ref="addPhotography"
            v-if="$parent.pageType == 'addPhotography'"
            :orderForm="orderForm"
        />
        <el-dialog
            title="編輯訂單簡介"
            :show-close="false"
            :visible.sync="orderVisible"
            append-to-body
            :close-on-click-modal="false"
            width="70%"
        >
            <div>
                <el-form ref="form" :model="orderForm" label-width="100px">
                    <el-form-item label="婚宴日期">
                        <el-date-picker v-model="orderForm.orderMain.weddingDate" type="date" placeholder="選擇日期" :editable="false" style="width: 100%;"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="舉行地址">
                        <!-- <el-input v-model="orderForm.orderMain.weddingLocation"></el-input> -->
                        <el-select
                            v-model="orderForm.orderMain.weddingLocation"
                            placeholder="請選擇"
                            style="width: 100%;"
                            filterable
                            allow-create
                        >
                            <el-option
                                v-for="item in weddingLocation"
                                :key="item.value"
                                :label="item.name"
                                :value="item.name"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="攝影套餐">
                        <el-select
                                v-model="orderForm.orderInfo.shootingContent"
                                placeholder="請選擇"
                                style="width: 100%;"
                                filterable
                                allow-create
                        >
                            <el-option
                                    v-for="item in shootingContentOptions"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.name"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="婚宴優惠券">
                        <el-input v-model="orderForm.orderInfo.coupon"></el-input>
                    </el-form-item>
                    <el-form-item label="營業員">
                        <el-select
                            v-model="orderForm.orderInfo.staffs"
                            multiple
                            :multiple-limit = "4"
                            placeholder="請選擇"
                            style="width: 100%;"
                            >
                                <el-option
                                    v-for="item in staffType"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value"
                                >
                            </el-option>
                        </el-select>
                        <!-- <el-input v-model="orderForm.orderInfo.staffName"></el-input> -->
                    </el-form-item>
                    <el-form-item label="總額">
                        <el-input-number v-model="orderForm.orderInfo.amount" :controls="false" :min="0" :precision="2" style="width: 100%;"></el-input-number>
                    </el-form-item>
                    <el-form-item label="備註">
                        <el-input type="textarea" v-model="orderForm.orderInfo.remarkInvoice" :autosize="{minRows:6}"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    @click="orderVisible = false"
                >
                    取消
                </el-button>
                <el-button
                    type="primary"
                    @click="submitOrder"
                    :loading='submitLoading'
                >
                    確定
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import AddPhotography from './AddPhotography.vue'
import { Dialog } from 'vant';
export default {
    components:{AddPhotography},
    props:{
        id:{
            typeof:String | Number,
            default:0
        }
    },
    data(){
        return {
            detailInfo:[
                {label:'婚宴日期',key:'weddingDate',value:'',showAll:false},
                {label:'舉行地址',key:'weddingLocation',value:'',showAll:false},
                {label:'攝影套餐',key:'shootingContent',value:'',showAll:false},
                {label:'婚宴優惠券',key:'coupon',value:'',showAll:false},
                {label:'單據編號 ',key:'orderAId',value:'',showAll:false},
                {label:'營業員',key:'staffName',value:'',showAll:false},
                {label:'總額',key:'amount',value:'', type:'number',isExceed:false,showAll:false},
                {label:'備注',key:'remarkInvoice',value:'',showAll:false}
            ],
            // reservationInfo:[
            //     {label:'',key:''},
            //     {value:'試衫日期',key:''},
            //     {value:'攝影日期',key:''},
            //     {value:'預約時間',key:''}
            //     // {value:'',key:'orderForm.orderInfo.tryDateReal',isClick: true, isEdit: true},
            //     // {value:'',key:'orderForm.orderInfo.shootingDateReal',isClick: true, isEdit: true},
            // ],
            orderVisible:false,
            orderForm:{
                orderMain:{
                    weddingDate:''
                },
                orderInfo:{}
            },
            staffType:[],
            weddingLocation:[],
            shootingContentOptions: [],
            submitLoading:false
        }
    },
    mounted(){
        this.getInfo();
        let coreDict = JSON.parse(localStorage.getItem('coreDict'));
        this.staffType = coreDict.filter(item => item.type === 'staff' && item.name && item.name.replace('&nbsp;', '').replace(' ', '') !== '');
        this.weddingLocation = coreDict.filter(item => {return  item.type === 'wedding_location'});
        this.shootingContentOptions = coreDict.filter(item => item.type === 'shooting_package');
    },
    methods:{
        getInfo(){
            this.$api.customer.viewOrderA(this.id).then(res => {
                if(res.code === 0 && res.data) {
                    this.orderForm = JSON.parse(JSON.stringify(res.data));
                    this.orderForm.orderInfo.staffs = this.orderForm.orderInfo.staffs.reduce((initArr,item) => {
                        initArr.push(item.value);
                        return initArr;
                    },[])
                    this.detailInfo.forEach((element,index) => {
                        if(res.data.orderInfo[element.key]) {
                            element.value = res.data.orderInfo[element.key]
                        }
                        if(res.data.orderMain[element.key]) {
                            element.value = res.data.orderMain[element.key]
                        }
                        if(element.key == 'staffName') {
                            // 處理營業員
                            element.value = res.data.orderInfo.staffs.reduce((initArr,item) => {
                                initArr.push(item.name);
                                return initArr;
                            },[]);
                            element.value = element.value.join(",");
                        }

                        this.$nextTick(() => {
                            this.isShowMore(element.key,index);
                        })
                    })

                    // this.reservationInfo.forEach(element => {
                    //     if(element.key) {
                    //         element.value = res.data.orderInfo[element.key];
                    //     }
                    // })
                }
            })
        },
        addInfo(isClick){
            if(!isClick) return;
            this.$parent.pageType = 'addPhotography';
        },
        handleEdit(){
            this.orderVisible = true;
        },
        saveInfo(params) {
            this.$api.customer.saveOrderA(params).then(res => {

            });
        },
        submitOrder(){
            let params = JSON.parse(JSON.stringify(this.orderForm));
            params.orderInfo.staffs = params.orderInfo.staffs.map(item => {return {value:item}});
            params.formOrderDetailPage = false;
            this.submitLoading = true;
            this.$api.customer.saveOrderA(params).then(res => {
                this.submitLoading = false;
                let { code, msg } = res;
                if(code === 0) {
                    if (msg === '成功') {
                        this.orderVisible = false;
                        this.$message.success('操作成功');
                        this.getInfo();
                    } else {
                        this.orderVisible = false;
                        Dialog.confirm({
                            title: '',
                            message: msg,
                            confirmButtonColor:"#0052D9",
                            confirmButtonText: '確定',
                            cancelButtonColor:"#666",
                            cancelButtonText: '取消'
                        }).then(() => {
                            // on confirm
                            params.ignoreWarning = true;
                            this.$api.customer.saveOrderA(params).then(res => {
                                let {code, msg} = res;
                                if (code === 0) {
                                    this.$message.success('操作成功');
                                    this.getInfo();
                                } else {
                                    this.$message.error(msg);
                                }
                            });
                        }).catch(() => {
                                // on cancel
                        });
                    }
                } else {
                    this.$message.error(msg);
                }
            })
        },
        handleShowAll(index,key){
            this.detailInfo[index].showAll = !this.detailInfo[index].showAll;
            let dom = document.getElementsByClassName('detail-info-value');
            let childDom = document.getElementById(key);
            let moreDom = document.getElementsByClassName('more');
            console.log(moreDom,'moreDom')
            if(this.detailInfo[index].showAll) {
                dom[index].style.whiteSpace = 'normal';
                childDom.style.whiteSpace = 'normal';
                childDom.style.maxWidth = '100%';
                childDom.style.display = 'inline';
                moreDom[index].style.position = 'static'
            } else {
                dom[index].style.whiteSpace = 'nowrap';
                childDom.style.whiteSpace = 'nowrap';
                childDom.style.maxWidth = '80%';
                childDom.style.display = 'inline-block';
                moreDom[index].style.position = 'absolute'
            }
        },
        isShowMore(key,index){
            this.$nextTick(() => {
                let dom = document.getElementById(key);
                let domArr = document.getElementsByClassName('detail-info-value');
                if(dom) {
                    let parentWidth = (domArr[0].offsetWidth - 12)*0.8;
                    let childWidth = dom.getBoundingClientRect().width;
                    if(parentWidth - childWidth < 5) {
                        let obj = this.detailInfo.find(item => {return item.key === key});
                        obj.isExceed = true;
                        this.$set(this.detailInfo,index,obj);
                    }

                }
            })
        },
        changeValue(parentObj, key, v) {
            if (!parentObj) {
                return;
            }
            parentObj[key] = v;
            let params = JSON.parse(JSON.stringify(this.orderForm));
            params.orderInfo.staffs = params.orderInfo.staffs.map(item => {return {value:item}});
            params.formOrderDetailPage = true;
            this.saveInfo(params);
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    background-color: #fff;
    margin-top: 24px;
    padding: 24px;

    .title {
        color: #333;
        font-weight: 600;
        font-size: 16px;
        padding-bottom: 24px;
        border-bottom: 1px solid #e5e5e5;
        text-align: center;
        margin-bottom: 24px;
        margin-left: 20px;
    }

    .detail-info,.reservation {
        display: flex;
        flex-wrap: wrap;
        padding-left: 24px;
        .info-item {
            position: relative;
            width: calc(50% - 8px);
            display: flex;
            margin-bottom: 16px;
            margin-right: 16px;
            > span {
                display: inline-block;
                padding: 5px 0;
                // min-height: 30px;
                // line-height: 30px;
                font-size: 14px;
                border: 1px solid #e5e5e5;
            }
            .label {
                width: 35%;
                border-right: none;
                color: #666;
                background-color: #F8F8F8;
            }
            .value {
                position: relative;
                flex: 1;
                color: #333;
                padding: 5px 6px;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
                display: inline-block;
                white-space: nowrap;
                .text {
                    max-width: 80%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    white-space: nowrap;
                }
            }
            .more {
                position: absolute;
                top: 4px;
                right: 10px;
                color: rgb(0, 82, 217);
                font-size: 12px;
                border: none !important;
                margin-left: 10px;
            }
        }
    }

    .detail-info > div:nth-child(even) {
        margin-right: 0;
    }

    .reservation {
        .info-item {
            width: 25%;
            margin-bottom: 0;
            margin-right: 0;

            .value {
                flex: 1;
            }
        }
    }
}
.container-bottom {
    margin-bottom: 100px;
}

::v-deep .el-input-number .el-input__inner {
    text-align: left;
}
::v-deep .el-icon-date-clear {
    display: none;
}

::v-deep .reservation-date-picker {
    width: 100% !important;

    .el-input__inner {
        text-align: center;
    }

    .el-input__prefix {
        display: none;
    }
}
</style>
