<template>
    <div class="reservation container-bottom">
        <div class="detail-info">
            <div class="info-item">
                <span class="label">顧客編號</span>
                <span class="value">{{ detailData?.customer?.customerId }}</span>
            </div>
            <div class="info-item">
                <span class="label">姓氏</span>
                <span class="value">{{ detailData?.customer?.familyNames }}</span>
            </div>
            <div class="info-item">
                <span class="label">婚宴日期</span>
                <span class="value">{{ detailData?.customer?.weddingDate }}</span>
            </div>
            <br/>
            <div class="info-item" v-for="(item,index) in detailInfo" :key="index">
                <span class="label">{{ item.label }}</span>
                <span class="value">
                    <el-date-picker
                        v-model="detailData[item.key]"
                        type="date"
                        placeholder=""
                        v-if="item.type == 'date'"
                        :editable="false"
                        :clearable="true"
                        value-format="yyyy-MM-dd"
                        prefix-icon=""
                        @change="v => {changeDate(v,item.key)}"
                    >
                    </el-date-picker>
                    <span
                        contenteditable="true"
                        v-else
                        style="width: 100%;"
                        @blur="(e) => {handleBlur(e.target.innerText, item.key)}"
                        > {{ detailData[item.key] }} </span>
                    <!-- {{ detailData[item.key] }} -->
                </span>
            </div>
        </div>

        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="預約" name="reservation"></el-tab-pane>
            <el-tab-pane label="Fitting" name="fitting"></el-tab-pane>
        </el-tabs>

        <div  v-if="activeName == 'reservation'" class="table-container">
            <table border="1" cellpadding="10" cellspacing="0" class="table" v-for="(bookInfos,bookInfosIndex) in detailData.bookInfos" :key="bookInfosIndex">
                <tr>
                    <th v-for="(item,index) in reservationTh" :key="index">{{ item }}</th>
                </tr>
                <tr style="height: 100px;">
                    <td>試衫</td>
                    <td v-for="(item,index) in dressAsArray" :key="index" @click="goSearch(bookInfosIndex, 'booking', item, 'trying')">
                        <p v-for="(v,index1) in handleContent(item, bookInfos.clothesDressAsList, 'trying')" :key="index1" class="clothing">
                            {{ v.code }}
                            <svg-icon
                                icon-class="delete"
                                style="font-size: 9px;margin-left: 8px;"
                                @click.stop="handleDelete(bookInfosIndex, 'booking', item, 'trying', v)"
                                v-if = "bookInfosIndex === 0"
                            />
                        </p>
                    </td>
                </tr>
                <tr style="height: 100px;">
                    <td>定款</td>
                    <td v-for="(item,index) in dressAsArray" :key="index" @click="goSearch(bookInfosIndex, 'booking', item, 'determinate')">
                        <p v-for="(v,index1) in handleContent(item, bookInfos.clothesDressAsList, 'determinate')" :key="index1" class="clothing">
                            {{ v.code }}
                            <svg-icon
                                icon-class="delete"
                                style="font-size: 9px;margin-left: 8px;"
                                @click.stop="handleDelete(bookInfosIndex, 'booking', item, 'determinate', v)"
                                v-if = "bookInfosIndex === 0"
                            />
                        </p>
                    </td>
                </tr>
                <tr style="height: 100px;">
                    <td>轉款</td>
                    <td v-for="(item,index) in dressAsArray" :key="index" @click="goSearch(bookInfosIndex, 'booking', item, 'translated')">
                        <p v-for="(v,index1) in handleContent(item, bookInfos.clothesDressAsList, 'translated')" :key="index1" class="clothing">
                            {{ v.code }}
                            <svg-icon
                                icon-class="delete"
                                style="font-size: 9px;margin-left: 8px;"
                                @click.stop="handleDelete(bookInfosIndex, 'booking', item, 'translated', v)"
                                v-if = "bookInfosIndex === 0"
                            />
                        </p>
                    </td>
                </tr>
                <tr style="height: 100px;">
                    <td colspan="13">
                        <div class="flex">
                            <span class="img" v-for="(item,index) in bookInfos.imageList" :key="index">
                                <img :src="item" @click="goPreview(item)">
                                <svg-icon
                                    icon-class="delete-icon"
                                    style="font-size: 14px;"
                                    @click.stop="deleteImg(bookInfosIndex,index)"
                                    class="delete-icon"
                                    v-if="bookInfosIndex === 0"
                                />
                            </span>
                            <span v-if="bookInfosIndex === 0 && bookInfos.imageList.length < 3" class="upload" @click.stop="uploadFile">
                                <i class="el-icon-plus" style="font-size: 30px;margin-bottom: 12px;"></i>
                                <span>上傳圖片</span>
                            </span>
                            <p class="content">
                                <span>預約時間：{{ bookInfos.time }}</span>
                                <span>預約次數：{{ bookInfos.numberOfBooking }}</span>
                                <span>備註：{{ bookInfos.remarks }}</span>
                            </p>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div v-if="activeName == 'fitting'" class="table-container">
            <table border="1" cellpadding="10" cellspacing="0" class="table" v-for="(fittingInfos,fittingInfosIndex) in detailData.fittingInfos" :key="fittingInfosIndex">
                <tr>
                    <th v-for="(item,index) in reservationTh.filter(item => {return item !== ''})" :key="index">{{ item }}</th>
                </tr>
                <tr style="height: 100px;">
                    <td v-for="(item,index) in dressAsArray" :key="index">
                        <p v-for="(v,index1) in handleContent(item, fittingInfos.clothesDressAsList)" :key="index1" style="font-size: 12px;display: flex;white-space: nowrap;">
                            {{ v.code }}
                        </p>
                    </td>
                </tr>
                <tr style="height: 100px;">
                    <td colspan="13">
                        <p class="content" style="margin: 0;">
                            <span>預約時間：{{ fittingInfos.time }}</span>
                            <span>預約次數：{{ fittingInfos.numberOfBooking }}</span>
                            <span>備註：{{ fittingInfos.remarks }}</span>
                        </p>
                    </td>
                </tr>
            </table>
            <Empty v-if="detailData.fittingInfos.length == 0" imagew="150" style="margin-top: 10%" />
        </div>

        <ClothInfo ref="clothInfo" @submit="submitClothing"/>
        <input
            v-show="false"
            type="file"
            id="fileInput"
            @change="handleFileChange"
            name="file"
            ref="file"
        />
    </div>
</template>

<script>
import ClothInfo from './ClothInfo.vue';
import { Dialog,ImagePreview  } from 'vant';
import Empty from '@/components/Empty.vue';
export default {
    components:{ClothInfo,Empty},
    props:{
        id:{
            typeof:String | Number,
            default:0
        }
    },
    data(){
        return {
            activeName:'reservation',
            detailInfo:[
                {label:'取衫日期',key:'getClothesDate',type:'date'},
                {label:'負責同事',key:'getClothesStaff'},
                {label:'還衫日期',key:'returnClothesDate',type:'date'},
                {label:'負責同事',key:'returnClothesStaff'}
            ],
            reservationTh:['','婚紗','晚裝','女裙褂','男禮','馬褂','男媽','女媽','男爸','女爸','伴郎','伴娘','其它'],
            dressAsArray: ['wWeddingDress', 'wEveningDress', 'gown', 'mFormalDress', 'jacket', 'mMon', 'wMon', 'mDad', 'wDad', 'groomsman', 'bridesmaid', 'others'],
            detailData:{
                images: []
            }
        }
    },
    mounted(){
        this.getInfo();
    },
    methods:{
        getInfo(){
            // this.id = 'B2399';
            this.$api.customer.viewOrderB(this.id).then(res => {
                let { code, data, msg } = res;
                if(code === 0 && data) {
                    // this.detailData = JSON.parse(JSON.stringify(res.data));
                    let rentInfo = data['rentInfo'] || {};
                    let bookInfos = data['bookInfos'] || [];
                    this.detailData = {
                        customer: data.customer,
                        orderType: data.orderType,
                        orderCode: data.orderCode,
                        getClothesDate: rentInfo.getClothesDate || "",
                        getClothesStaff: rentInfo.getClothesStaff,
                        returnClothesDate: rentInfo.returnClothesDate || "",
                        returnClothesStaff: rentInfo.returnClothesStaff,
                        images: bookInfos.length > 0 ? bookInfos[0]['imageList'] || [] : [],
                        bookInfos,
                        fittingInfos: data.fittingInfos || []
                    }
                } else {
                    this.$message.error(msg);
                }
            })
        },
        handleClick(){},
        goSearch(index, rentType, dressAs, clothesUseStatus) {
            if (index !== 0) {
                return;
            }
            this.$refs.clothInfo.isExternal = true;
            let { orderType, orderCode } = this.detailData;
            this.$refs.clothInfo.open(orderType, orderCode, dressAs, rentType, clothesUseStatus);
        },
        submitClothing(code){
            this.getInfo();
        },
        handleContent(key, data, clotheUseStatus){
            let clothesInfos = data.find(item => item.dressAs === key)?.['clothesInfos'];
            if(clothesInfos) {
                if (clotheUseStatus) {
                    clothesInfos = clothesInfos.filter(o => (o.status || []).includes(clotheUseStatus));
                }
                return clothesInfos.map(o => o['clothes'] || []);
            }
            return [];
            // return [{code:'C1234-11',code:'C1234-22',code:'C1234-3'}]
        },
        handleDelete(index, rentType, dressAs, clothesUseStatus, item){
            if (index !== 0) {
                return;
            }
            let { orderType, orderCode } = this.detailData;
            Dialog.confirm({
                title: '',
                message: `是否確認刪除"${item.code}"該服裝`,
                confirmButtonColor:"#0052D9",
                confirmButtonText: '確定',
                cancelButtonColor:"#666",
                cancelButtonText: '取消'
            }).then(() => {
                // on confirm
                this.$api.customer.removeClothesBookingInfo({
                    orderType, orderCode, dressAs, rentType, clothesUseStatus,
                    bizClothes: item.id,
                }).then(res => {
                    if(res.code === 0) {
                        this.getInfo();
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            })
                .catch(() => {
                    // on cancel
                });
        },
        goPreview(url){
            ImagePreview([url]);
        },
        deleteImg(index,imgIndex){
            Dialog.confirm({
                title: '',
                message: `是否確認刪除該圖片`,
                confirmButtonColor:"#0052D9",
                confirmButtonText: '確定',
                cancelButtonColor:"#666",
                cancelButtonText: '取消'
            }).then(() => {
                    // on confirm
                    this.detailData.images.splice(imgIndex,1);
                    this.saveInfo();
                })
                .catch(() => {
                    // on cancel
                });
        },
        uploadFile(){
            this.$refs.file.click()
        },
        handleFileChange(e){
            let file = e.target.files[0];
            var regex = /^(?:jpg|jpeg|png|bmp)$/i;
            if(!regex.test(file.type.split("/")[1])) {
                this.$message.error('請上傳圖片格式');
                return
            }
            this.$utils.ossUploadFile(file).then(res => {
                this.detailData.images.push(res);
                this.saveInfo();
            })
        },
        changeDate(e, key){
            if (e) {
                e += " 00:00:00";
            }
            this.detailData[key] = e;
            this.saveInfo();
        },
        handleBlur(e, key){
            this.detailData[key] = e;
            this.saveInfo();
        },
        saveInfo(){
            let { orderCode, getClothesDate, getClothesStaff, returnClothesDate, returnClothesStaff, images } = this.detailData;
            let requestData = { orderCode, getClothesDate, getClothesStaff, returnClothesDate, returnClothesStaff, images };
            this.$api.customer.saveRentInfoOfOrderB(requestData).then(res => {
                if(res.code === 0) {
                    this.getInfo();
                } else {
                    this.$message.error(res.msg);
                }
            })
        }

    }
}
</script>

<style lang="scss" scoped>
.reservation {
    background-color: #fff;
    padding: 24px;

    .detail-info {
        display: flex;
        flex-wrap: wrap;
        .info-item {
            width: calc(50% - 8px);
            display: flex;
            margin-bottom: 16px;
            margin-right: 16px;
            span {
                display: inline-block;
                height: 30px;
                line-height: 30px;
                font-size: 14px;
                border: 1px solid #e5e5e5;
            }
            .label {
                width: 35%;
                border-right: none;
                color: #666;
                background-color: #F8F8F8;
            }
            .value {
                flex: 1;
                color: #333;
            }
        }
    }

    .detail-info>.info-item:nth-child(even) {
        margin-right: 0;
    }

    .table-container {
        width: 100%;
        overflow-x: auto;
    }

    .table {
        border-color: #eee;
        margin-bottom: 24px;
        width: 100%;
        th {
            min-width: 70px;
        }

        .flex {
            display: flex;

            .upload {
                /*display: inline-block;*/
                width: 90px;
                height: 90px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                border: 1px solid #666;
                margin-right: 18px;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 0 0 0 0;
            span {
                margin: 0 0 10px 0;
            }
        }

        .img {
            position: relative;
            display: inline-block;
            margin-right: 12px;
            img {
                width: 90px;
                height: 90px;
            }
            .delete-icon {
                position: absolute;
                top: -7px;
                right: -7px;
                background-color: white;
                border-radius: 11px;
            }

        }
        .clothing {
            display: flex;
            align-items: center;
            font-size: 12px;
            white-space: nowrap;
        }
    }
}
.container-bottom {
    margin-bottom: 100px;
}
::v-deep .el-date-editor {
    width: 100% !important;
}
::v-deep .el-input__inner {
    width: 100% !important;
    height: 32px;
    // border: none;
}
::v-deep .el-icon-date:before {
    display: none;
}
::v-deep .el-icon-circle-close {
    line-height: 30px;
}
</style>
