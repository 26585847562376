<template>
    <div class="calendar-container">
        <Header title="日曆統計"/>
        <div v-loading="loadingPage" element-loading-text="加載中">
            <div class="calendar">
                <div class="calendar-header">
                    <img src="../assets/double_left.png" style="width: 24px;height: 24px;" @click="changeTime('double-left')">
                    <img src="../assets/left_icon.png" style="width: 20px;height: 20px;margin-left: 20px;" @click="changeTime('left')">
                    <span class="content">
                        <!-- {{ formatMonth(currentCalendar) }} -->
                        <el-date-picker
                            v-model="currentCalendar"
                            type="month"
                            placeholder="選擇月"
                            format="yyyy 年 MM 月"
                            :editable="false"
                            :clearable="false"
                            class="current-calendar"
                            style="width: 110px;"
                        >
                        </el-date-picker>
                    </span>
                    <img src="../assets/right_icon.png" style="width: 20px;height: 20px;margin-right: 20px;" @click="changeTime('right')">
                    <img src="../assets/double_right.png" style="width: 24px;height: 24px;" @click="changeTime('double-right')">
                </div>
                <el-calendar v-model="currentCalendar">
                    <template
                        slot="dateCell"
                        slot-scope="{data}"
                    >
                        <div :class="{ needlessWeek: needlessWeek(data.day), selectDay: data.day === currentDay }">
                            <p style="font-size: 16px;">{{ Number(data.day.split('-').slice(2)[0]) }}</p>
                            <p style="font-size: 12px;">{{ handleCalendarCount(data.day) }}</p>
                        </div>
                    </template>
                </el-calendar>
            </div>

            <div style="background-color: #fff;padding: 24px;">
                <table border="1" cellpadding="10" cellspacing="0" class="table" v-if="customerList.length > 0">
                    <tr>
                        <th colspan="2">{{ currentDay }}</th>
                    </tr>
                    <tr>
                        <td style="width: 50%">客戶名稱</td>
                        <td style="width: 50%">客戶編號</td>
                    </tr>
                    <tr v-for="(item,index) in customerList" :key="index">
                        <td>{{item.familyNames}}</td>
                        <td>{{item.customerId}}</td>
                    </tr>
                </table>
                <Empty v-else />
            </div>
        </div>
    </div>
</template>

<script>
import Empty from '@/components/Empty.vue';
import Header from '@/components/Header.vue'
export default {
    components:{ Empty, Header },
    data(){
        return {
            value2:'',
            loadingPage:false,
            currentCalendar:'',
            currentDay:'',
            year:new Date().getFullYear(),
            month:new Date().getMonth() +1,
            monthCountData:[],
            calendarRange:[],
            customerList:[]
        }
    },
    watch: {
        currentCalendar(newValue, oldValue) {
            let dateStr = this.formatDate(newValue);
            if (dateStr.length === 10) {
                this.getCustomerCount();
                this.clickDateCell(newValue);
            }
        }
    },
    computed:{
        handleCalendarCount(){
            return function (date) {
                let obj = this.monthCountData.find(item =>  item.date === date);
                let count = obj?.customerCount || 0;
                return count > 0 ? (obj?.customerCount || 0) + "個" : "";
            }
        },
        needlessWeek() {
            return function (day) {
                let firstDayOfThisWeek = this.monthCountData.find(item => item.date === day)?.['firstDayOfThisWeek'];
                if (!firstDayOfThisWeek) {
                    firstDayOfThisWeek = day;
                }
                return firstDayOfThisWeek > this.calendarRange[1];
            }
        }
    },
    mounted(){
        var year = new Date().getFullYear();
        var month = new Date().getMonth() +1 < 10 ? `0${new Date().getMonth() +1}` : new Date().getMonth() +1;
        var day = new Date().getDate();
        this.currentCalendar = `${year}-${month}`;
        this.currentDay = `${year}-${month}-${day}`;
        this.getCustomerCount();
        this.getCustomerList();
    },
    methods:{
        getCustomerCount(){
            let monthStr = this.formatMonth(this.currentCalendar, '-');
            this.loadingPage = true;
            this.$api.calendar.customerCount(monthStr).then(res => {
                this.loadingPage = false;
                let { code, data, msg } = res;
                if(code === 0) {
                    this.monthCountData = data['dayList'] || [];
                    this.calendarRange = data.calendarRange;
                } else {
                    this.$message.error(msg);
                }
            })
        },
        getCustomerList(){
            this.$api.calendar.customerStatisticsList(this.currentDay).then(res => {
                if(res.code === 0) {
                    this.customerList = res.data;
                    // this.calendarRange = [res.data[0].date,res.data[res.data.length - 1].date]
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        changeTime(type){
            this.currentCalendar = this.formatDate(this.currentCalendar);
            let year = this.currentCalendar.split('-')[0];
            let month = this.currentCalendar.split('-')[1]
            if(type == 'double-left'){
                this.currentCalendar = `${Number(year) - 1}-${month}`;
            }else if (type == 'double-right') {
                this.currentCalendar = `${Number(year) + 1}-${month}`;
            }else if (type == 'left') {
                if(month == 1) {
                    this.currentCalendar = `${Number(year) - 1}-12`;
                } else {
                    let monthValue = Number(month) - 1;
                    this.currentCalendar = `${year}-${monthValue < 10 ? '0'+ monthValue : monthValue}`;
                }
            }else if (type == 'right') {
                if(month == 12) {
                    this.currentCalendar = `${Number(year) + 1}-01`;
                } else {
                    let monthValue = Number(month) + 1;
                    this.currentCalendar = `${year}-${monthValue < 10 ? '0'+monthValue : monthValue}`;
                }
            }
            this.currentDay = `${this.currentCalendar}-01`;
            this.getCustomerCount();
            this.getCustomerList();
        },
        clickDateCell(date){
            this.currentDay = this.formatDate(date);
            this.getCustomerList();
            // e.target.offsetParent.style.background = '#0052D9';
        },
        formatDate(date) {
            if(typeof date == 'object') {
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const monthStr = month < 10 ? '0' + month : month;
                const dayStr = day < 10 ? '0' + day : day;
                return `${year}-${monthStr}-${dayStr}`;
            }
            return date;
        },
        formatMonth(date, delimiter) {
            let strArray = this.formatDate(date).split('-');
            return delimiter ? `${strArray[0]}${delimiter}${strArray[1]}` : `${strArray[0]}年${strArray[1]}月`;
        }
    }
}
</script>

<style lang="scss" scoped>
.calendar-container {
    background-color: #f5f5f5;padding: 24px;height: calc(100vh - 156px);overflow: auto;
}
.calendar {
    background-color: #fff;
    margin-bottom: 24px;
    border-radius: 8px;

    .calendar-header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 24px;
        .content {
            font-size: 18px;
            color: #000;
            margin: 0 30px;
        }
    }
}

.table {
    border-color: #eee;
    width: 100%;
}
::v-deep .el-calendar__header {
    display: none;
}
::v-deep .is-today {
    /*background-color: #0052D9;*/
    /*color: #fff !important;*/
}
::v-deep .el-calendar-table td.is-selected {
    background-color: #fff;
}
::v-deep tr.el-calendar-table__row:has(.needlessWeek) {
    display: none;
}
::v-deep div.el-calendar-day:has(.selectDay) {
    background-color: #409EFF;
    color: #fff !important;
}
::v-deep .el-calendar-table:not(.is-range) td.prev {
    color: #2c3e50 !important;
    background-color: #F5F5F5;
}
::v-deep .el-calendar-table:not(.is-range) td.next {
    color: #2c3e50 !important;
    background-color: #F5F5F5;
}
::v-deep .current-calendar {
    .el-input__prefix {
        display: none;
    }
    .el-input__inner {
        border: none;
        font-size: 16px;
        color: #333;
        padding: 0;
    }
}
</style>
