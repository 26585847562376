// import Vue from 'vue';
import axios from 'axios';
// import { getRouter } from '@/router';
import qs from 'qs';
import {
    Message,
    Loading
} from 'element-ui';
// import { getStore } from '@/store';

const SECOND = 120;
const defaultDuration = 3 * 1000;
// 超时时间
axios.defaults.timeout = SECOND * 1000;
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = false;
// axios.defaults.headers = {'Content-Type': 'application/json; charset=utf-8'}
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8';
// 非生产环境 && 开启代理, 接口前缀统一使用[/api]前缀做代理拦截!
const BASE_URL = window.mspApi;
// // 对面暴露的基础请求路径
axios.BASE_URL = BASE_URL;
/**
 * 请求拦截
 */
let loading;

const requestQueue = []; // 请求队列
const maxConcurrent = 6; // 最大并发请求数
let concurrentRequests = 0; // 当前并发请求数

// 历史报错消息
// let historyMessageList = [];
axios.interceptors.request.use(config => {
    // 判断堡垒机文件列表需要10秒左右获取状态
    if (config.identification) {
        config.timeout = 30 * 1000;
    }
    let showLoading = false;
    if (config.loading === true) {
        showLoading = true;
    }
    if (config.type == 'json') {
        config.headers['Content-Type'] = 'application/json;charset=utf-8';
    }
    if (showLoading) {
        loading = Loading.service({
            text: config.loadingText || 'Loading...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    }
    let token = '';
    if (config.data && config.data.token) {
        token = config.data.token;
    }
    if (config.params && config.params.token) {
        token = config.params.token;
    }
    if (!token) {
        token = localStorage.getItem('token');
    }
    // 请求头带上token
    if (!config.hideAuth) {
        config.headers.Authorization = token;
    }
    if (config.url !== '/build.txt' && !config.url.includes("goddess-com.oss-cn-hongkong.aliyuncs.com")) {
        let baseUrl = window.mspApi;
        config.url = baseUrl + config.url;
    }

    if (config.fullUrl) {
        config.url = config.fullUrl;
    }
    const type = config.method;
    // const defaults = {};
    const arrayFormat = config.headers.arrayFormat || 'indices';

    const newData = config.data;
    // 删除'_d_'开头，不需要传给后端的字段。
    Object.keys(config?.data || {}).forEach(key =>{
        if (typeof key === 'string' && key.startsWith('_d_')) {
            Reflect.deleteProperty(newData, key);
        }
    });

    if (
        type === 'post' &&
    config.headers['Content-Type'] ===
        'application/x-www-form-urlencoded; charset=utf-8'
    ) {
    // post请求参数处理
        config.data = qs.stringify(newData, {
            allowDots: true,
            arrayFormat: arrayFormat
        });
    }
    // config.params = qs.stringify(config.params, { allowDots: true, arrayFormat: arrayFormat });
    // config.params = qs.parse(config.params);
    // config.params = merge(defaults, config.params);
    //  /(msp|monitoring)-api/.test(config.url)
    // if (config.url && /(msp|monitoring)/.test(config.url)) {
    //     const store = getStore();
    //     config.headers['i18n-language'] = store.state.globalTheme.lang === 'zh-CN' ? 'zh_CN' : 'en_US';
    // }
    // 控制请求并发
    if (concurrentRequests < maxConcurrent) {
        // console.log('允许的并发数', Date.now());
        // 如果并发请求数量小于最大并发数，直接发送请求
        concurrentRequests++;
        return config;
    } else {
        // console.log('超过并发数', Date.now());
        // 如果并发请求数量达到最大并发数，将请求添加到队列中
        return new Promise((resolve) => {
            requestQueue.push({
                config,
                resolve
            });
        });
    }

}, error => {
    console.log(error);
    return Promise.reject(error);
});
/**
 * 响应拦截
 */
axios.interceptors.response.use(response => {

    concurrentRequests--; // 并发请求数量减2

    if (requestQueue.length > 0) {
        // 如果请求队列中有等待的请求，发送下一个请求
        const { config, resolve } = requestQueue.shift();
        concurrentRequests++;
        resolve(config);

    }

    // let router = getRouter();
    if (loading) {
        loading.close();
    }
    // console.log(response,'response')
    if (response.data && response.data.code != 200) {
        if (response.data.message) {
            if (
                response.data.code != 1 &&
                    response.data.code != 108 &&
                    response.data.code != 403
            ) {
                Message({
                    message: response.data.message,
                    type: 'error',
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    duration: defaultDuration,
                    customClass: 'zZindex'
                });
            }
        }
    }

    return response;
}, error => {
    concurrentRequests--; // 并发请求数量减一
    if (error.message.includes('timeout')) {
        Message({
            message: '请求服务器超时',
            type: 'error',
            showClose: true,
            duration: defaultDuration,
            customClass: 'zZindex'
        });
    } else if (error?.response?.status === 401) { // 超时自动刷新
        if (error.response.data.code === 401) {
            // ExpiredLog();
        }
        // router.push({ name: 'login' });
    } else if (error?.response?.status === 402) { // 402 未登录或者refresh token过时， 403 账号在其他地方登录
        // router.push({ name: 'login' });

    } else if (error?.response?.status === 403) { // 402 未登录或者refresh token过时， 403 账号在其他地方登录
        // router.push({ name: 'login' });
    } else if (error?.response?.status === 404) { // 路径找不到
        let message = '404，路径找不到' + ':' + error.response.data.path;
        Message({
            message: message,
            type: 'error',
            showClose: true,
            duration: defaultDuration
        });
    } else if (error.response.status === 500) {
        Message({
            message: error.response.data.msg,
            type: 'error',
            showClose: true,
            duration: defaultDuration,
            customClass: 'zZindex'
        });
    }
    return Promise.reject(error);

});
export default axios;
