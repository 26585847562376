<template>
    <div class="fitting-list">
        <el-table
            :data="tableData.clothesStatusList"
            border
            style="width: 100%; margin-top: 20px;"
            ref="myTable"
        >
            <template v-for="(item,index) in tablefields">
                <el-table-column
                    :prop="item.prop"
                    :label="item.label"
                    :key="index+'tablefields'"
                    :width="item.width ? item.width : item.minWidth"
                    style="white-space: nowrap;"
                >
                    <template slot-scope="scope">
                        <!-- <span>
                            <span @click="goReservationDetail(scope.row)" style="color: #0052D9;">
                                {{scope.row.bbb}}
                            </span>
                        </span> -->
                        <span v-if="item.prop == 'code'">{{ scope.row[item.prop] }}</span>
                        <span v-else :class="{'active-bg':scope.row[item.prop].allEnableThisDay}">
                            <!-- {{ scope.row }} -->
                            <svg-icon
                                icon-class="gou"
                                :style="{'font-size':'14px','color':scope.row[item.prop].allEnableThisDay ? '#fff' : '#0052D9'}"
                                v-if="scope.row[item.prop].enable"
                            />
                        </span>
                    </template>
                </el-table-column>
            </template>
        </el-table>

        <el-form ref="form" :model="form" label-width="80px" style="margin-top: 24px;">
            <el-form-item label="時間" class="date-picker">
                <el-date-picker
                    type="datetime"
                    placeholder="選擇時間"
                    v-model="form.time"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :editable="false"
                    style="width: 320px;"
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="備註">
                <el-input type="textarea" v-model="form.remarks" :autosize="{minRows:6}"></el-input>
            </el-form-item>

            <el-form-item label-width="0">
                <el-button type="primary" @click="onSubmit" style="width: 50%;margin-top: 24px;" :loading='loading'>確定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    data(){
        return {
            loading:false,
            tablefields:[
                // {prop:'aaa',label:'',width:'120'},
                // {prop:'bbb',label:'時間',minWidth:'150'},
                // {prop:'ccc',label:'類型',minWidth:'150'},
                // {prop:'ddd',label:'備註',minWidth:'150'},
            ],
            form:{
                orderCode:'',
                time:'',
                remarks:'',
                type:'fitting',
            },
            tableData:{}
        }
    },
    methods:{
        handleTableData(obj){
            this.form.orderCode = obj.orderCode;
            this.form.time = new Date().toLocaleString().replaceAll("/", "-");
            this.tablefields = obj.dates.reduce((initValue,item) => {
                initValue.push({prop:item,label:item,minWidth:'110'});
                return initValue;
            },[]);
            this.tablefields.unshift({prop:'code',label:'',minWidth:'110'})
            this.tableData = obj;
            this.$nextTick(() => {
                const tdNodes = this.$refs.myTable.$el.querySelectorAll('td');
                console.log(tdNodes,'tdNodes'); // 這裏你可以拿到所有的td節點
                [...tdNodes].forEach(e => {
                    if(e.children[0].children[0].className == 'active-bg') {
                        e.style.background = '#0052D9'
                    }
                    // console.log(e.children[0].children[0].className,'ccccccccccc')
                })
            })
        },
        onSubmit() {
            this.loading = true;
            this.$api.clothing.addRentBookingInfo(this.form).then(res => {
                this.loading = false;
                if(res.code == 0) {
                    this.$message.success('操作成功');
                    this.$emit('fittingSuccess');
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.fitting-list {
    background-color: #fff;
    padding: 24px;
}

::v-deep .date-picker {
    display: flex;
    .el-form-item__content {
        margin-left: 0 !important;
    }
}

::v-deep .el-table__row {
    .cell {
        text-align: center;
    }
}
</style>
