<template>
    <div style="background-color: #f5f5f5;" >
        <Header :title="handleTitle" :callback="handleBack"></Header>
        <div id="scroll-container" class="scroll-container">
            <div class="contianer" v-if="pageType !== 'addPhotography' && pageType !== 'fittingList' && pageType !== 'reservation'">
                <div class="title">基本信息</div>
                <div class="detail-info">
                    <div class="customer-id">
                        <div class="label">顧客編號：</div>
                        <div class="value">{{detailInfoData.customerId}}</div>
                    </div>
                    <div class="info-item" v-for="(item,index) in detailInfo" :key="index">
                        <span class="label">{{ item.label }}</span>
                        <span class="value">{{ detailInfoData[item.key] }}</span>
                    </div>
                </div>
            </div>

            <div class="contianer container-bottom" v-if="pageType == 'customer'">
                <div class="title">訂單信息</div>
                <el-table
                    :data="tableData"
                    border
                    style="width: 100%; margin-top: 20px;"
                >
                    <template v-for="(item,index) in tablefields">
                        <el-table-column
                            :prop="item.prop"
                            :label="item.label"
                            :key="index+'tablefields'"
                            :width="item.width ? item.width : item.autoWidth ? initWidth : ''"
                            :fixed="item.fixed"
                        >
                            <template slot-scope="scope">
                                <span v-if="item.prop == 'btn' && (scope.row.orderType == 'A' || scope.row.orderType == 'B')">
                                    <span @click="handleDetail(scope.row)" style="color: #0052D9;">
                                        詳情
                                    </span>
                                </span>
                                <span v-else>{{ scope.row[item.prop] }}</span>
                            </template>
                        </el-table-column>
                    </template>
                </el-table>
            </div>
            <Photography
                v-if="pageType == 'photography' || pageType == 'addPhotography'"
                ref="photography"
                :id="queryId"
            />
            <ExternalRental
                v-if="pageType == 'externalRental' || pageType == 'fittingList'"
                ref="externalRental"
                :id="queryId"
            />
            <Reservation
                v-if="pageType == 'reservation'"
                :id="queryId"
                ref="reservation"
            />
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Photography from './comp/Photography.vue';
import ExternalRental from './comp/ExternalRental.vue';
import Reservation from './comp/Reservation.vue';
export default {
    components:{Header,Photography,ExternalRental,Reservation},
    data(){
        return {
            detailInfo:[
                {label:'男士姓',key:'bridegroomNameEnglish'},
                {label:'女士姓',key:'brideNameEnglish'},
                {label:'男士姓名',key:'bridegroomNameChinese'},
                {label:'女士姓名',key:'brideNameChinese'},
                {label:'聯絡電話(男士)',key:'bridegroomPhone'},
                {label:'聯絡電話(女士)',key:'bridePhone'},
                {label:'Email(男士)',key:'bridegroomMail'},
                {label:'Email(女士)',key:'brideMail'},
                {label:'通訊地址(男士)',key:'bridegroomAddress'},
                {label:'通訊地址(女士)',key:'brideAddress'},
                {label:'婚宴日期',key:'weddingDate'},
                {label:'婚宴公司',key:'weddingCompany'},
                {label:'婚宴經理',key:'weddingCompanyBillingManager'},
                {label:'舉行地址',key:'weddingLocation'},
                {label:'證婚時間',key:'witnessDate'},
            ],
            detailInfoData:{},
            tableData:[],
            tablefields:[
                {prop:'orderChildId',label:'發票編號',width:'80'},
                {prop:'billingDate',label:'開單日期',width:'100'},
                {prop:'amount',label:'總額',width:'70'},
                {prop:'staffs',label:'營業員',width:'100'},
                {prop:'photographer',label:'攝影師',width:'100'},
                {prop:'assistant',label:'助攝',width:'100'},
                {prop:'videographer',label:'録影師',width:'100'},
                {prop:'dresser',label:'化妝師',width:'100'},
                {prop:'sampler',label:'看辨員',width:'100'},
                {prop:'typeName',label:'類型',width:'100'},
                {prop:'relatedOrder',label:'關聯訂單',width:'100'},
                {prop:'status',label:'狀態',width:'110'},
                {prop:'btn',label:'',width:'56',fixed:'right'},
            ],
            pageType:'customer',
            queryId:'',
        }
    },
    computed:{
        handleTitle() {
            let obj = {
                'customer':'顧客信息',
                'photography':'攝影預約信息',
                'addPhotography':'攝影預約信息',
                'externalRental':'外租預約',
                'fittingList':'Fitting列表',
                'reservation':'預約列表'
            }
            return obj[this.pageType];
        }
    },
    mounted(){
        let code = this.$route.query.code;
        this.$api.customer.getCustomerInfoByCode({code}).then(res => {
            if(res.code === 0 && res.data.customer) {
                this.detailInfoData = res.data.customer;
                this.tableData = res.data.orderChildList || [];
                this.detailInfoData.weddingDate = res.data.orderMain.weddingDate;
                this.detailInfoData.weddingCompany = res.data.orderMain.weddingCompany;
                this.detailInfoData.weddingCompanyBillingManager = res.data.orderMain.weddingCompanyBillingManager;
                this.detailInfoData.weddingLocation = res.data.orderMain.weddingLocation;
                this.detailInfoData.witnessDate = res.data.orderMain.witnessDate;
            }
        })
    },
    methods:{
        handleDetail(row){
            this.queryId = row['orderChildId'];
            if(row.orderType === 'A') {
                this.pageType = 'photography';
            } else if (row.orderType === 'B') {
                this.pageType = 'externalRental';
            }
            this.$nextTick(() => {
                document.getElementById('scroll-container').scrollTo({ top: 0 });
            })
        },
        handleBack(){
            if(this.pageType == 'customer') {
                this.$router.go(-1);
            } else if(this.pageType == 'photography' || this.pageType == 'externalRental') {
                this.pageType = 'customer'
            } else if(this.pageType == 'addPhotography') {
                this.pageType = 'photography';
                this.$nextTick(() => {
                    this.$refs.photography.getInfo();
                })
            }else if(this.pageType == 'fittingList') {
                this.pageType = 'externalRental'
            }else if(this.pageType == 'reservation') {
                this.pageType = 'externalRental'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.scroll-container {
    height: calc(100vh - 163px);
    overflow: auto;
}
.contianer {
    background-color: #fff;
    padding: 24px;
    margin-bottom: 24px;
    .title {
        color: #333;
        font-weight: 600;
        font-size: 16px;
        padding-bottom: 24px;
        border-bottom: 1px solid #e5e5e5;
        text-align: center;
        margin-bottom: 24px;
        margin-left: 20px;
    }
    
    .customer-id {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 24px;
        color: #333;
        margin-bottom: 16px;
        .label {
            font-weight: bolder;
        }
    }

    .detail-info {
        display: flex;
        flex-wrap: wrap;
        .info-item {
            width: calc(50% - 8px);
            display: flex;
            margin-bottom: 16px;
            margin-right: 16px;
            span {
                display: inline-block;
                height: 30px;
                line-height: 30px;
                font-size: 14px;
                border: 1px solid #e5e5e5;
            }
            .label {
                width: 35%;
                border-right: none;
                color: #666;
                background-color: #F8F8F8;
            }
            .value {
                flex: 1;
                color: #333;
            }
        }
    }

    .detail-info > div:nth-child(even) {
        margin-right: 0;
    }
}
.container-bottom {
    margin-bottom: 100px;
}
</style>
