import http from '@/utils/httpRequest';

export default {
    async customerCount(params) {
        let {data} = await http({
            url: `/cms/statistics/customerCount.json?month=${params}`,
            method: 'get',
            data: params,
        });
        return data;
    },
    async customerStatisticsList(params) {
        let {data} = await http({
            url: `/cms/statistics/customerList.json?day=${params}`,
            method: 'get',
            data: params,
        });
        return data;
    }
};