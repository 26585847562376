<template>
    <div style="background-color: #f5f5f5;padding: 24px;">
        <Header title="服裝信息"/>
        <div class="cloth-detail" v-loading='loading' element-loading-text="加載中">
            <div class="header-info">
                <img :src="detailInfo.image" v-if="detailInfo.image" class="logo" @click="goPreview">
                <img src="../../assets/no-img.png" class="logo" v-else>
                <div class="info">
                    <p v-for="(info,index1) in clothingInfo" :key="index1">
                        <span>{{ info.label }}：</span>
                        <span>{{ detailInfo[info.key] }}</span>
                    </p>
<!--                    TODO: 240423 暫時注釋-->
<!--                    <p>-->
<!--                        <span>已預定客人數量：</span>-->
<!--                        <span>{{ bookingCountThereafter }}次</span>-->
<!--                    </p>-->
                </div>
            </div>
            <div class="calendar">
                <div class="calendar-header">
                    <img src="../../assets/double_left.png" style="width: 24px;height: 24px;" @click="changeTime('double-left')">
                    <img src="../../assets/left_icon.png" style="width: 20px;height: 20px;margin-left: 20px;" @click="changeTime('left')">
                    <span class="content">
<!--                        {{ formatMonth(currentCalendar) }}-->
                        <el-date-picker
                                v-model="currentCalendar"
                                type="month"
                                placeholder="選擇月"
                                format="yyyy 年 MM 月"
                                :editable="false"
                                :clearable="false"
                                class="current-calendar"
                                style="width: 110px;"
                        >
                        </el-date-picker>
                    </span>
                    <img src="../../assets/right_icon.png" style="width: 20px;height: 20px;margin-right: 20px;" @click="changeTime('right')">
                    <img src="../../assets/double_right.png" style="width: 24px;height: 24px;" @click="changeTime('double-right')">
                </div>
                <el-calendar v-model="currentCalendar">
                    <template
                        slot="dateCell"
                        slot-scope="{data}">
                        <!-- <p>{{ date }}</p> -->
                        <!-- <p @click="aaaa(date,data)">{{ data }}</p> -->
                        <p :class="{
                            isSelect : handleCalendarSelect(data),
                            diffCustomerLastDay : diffCustomerLastDay(data),
                            diffCustomerNextDay : diffCustomerNextDay(data),
                            needlessWeek: needlessWeek(data)
                            }" @click.stop="() => {}"
                        >
                            {{ Number(data.day.split('-').slice(2)[0]) }}
                        </p>
                    </template>
                </el-calendar>
                <!-- <el-calendar v-model="calendarValue"></el-calendar> -->
            </div>

            <div class="table container-bottom">
                <el-button type="primary" icon="el-icon-plus" class="add-btn" @click="handleAdd" v-if="userInfo.isAdmin">新增</el-button>
                <el-table
                    :data="tableData"
                    :span-method="objectSpanMethod"
                    border
                    style="width: 100%; margin-top: 20px;"
                >
                    <template v-for="(item,index) in tablefields">
                        <el-table-column
                            :prop="item.prop"
                            :label="item.label"
                            :key="index+'tablefields'"
                            :width="item.width ? item.width : item.autoWidth ? initWidth : ''"
                        >
                            <template slot-scope="scope">
                                <span v-if="item.prop == 'btn' && userInfo.isAdmin">
                                    <span @click="handleEdit(scope.row)" style="color: #0052D9;margin-right: 10px;">
                                    編輯
                                    </span>
                                    <span @click="handleDelete(scope.row)" style="color: #ea0a0a;">
                                        刪除
                                    </span>
                                </span>
                                <span v-if="item.prop == 'month'">{{ Number(scope.row?.customer?.weddingDate.split('-')[1] ?? currentCalendar.split('-')[1]) }}月</span>
                                <span v-else-if="item.prop == 'weddingDate'">{{ scope.row?.customer?.weddingDate ? scope.row.customer.weddingDate.split(" ")[0] : '' }}</span>
                                <span v-else-if="item.prop == 'fixedStyle'">{{ scope.row.fixedStyle == true ? '是' : '否' }}</span>
                                <span v-else-if="item.prop == 'customerInfo'">{{ handlerCustomerInfo(scope.row) }}</span>
                                <span v-else-if="item.prop == 'recordDate'">{{ scope.row.recordDate ? scope.row.recordDate.split(" ")[0] : '' }}</span>
                                <span v-else-if="item.prop == 'startDate'">{{ scope.row.startDate ? scope.row.startDate.split(" ")[0] : '' }}</span>
                                <span v-else-if="item.prop == 'endDate'">{{ scope.row.endDate ? scope.row.endDate.split(" ")[0] : '' }}</span>
                                <span v-else>{{ scope.row[item.prop] }}</span>
                            </template>
                        </el-table-column>
                    </template>
                </el-table>
            </div>
        </div>

        <!-- <van-dialog
            v-model="showDialog"
            :title="dialogTitle"
            show-cancel-button
            width="70%"
            confirmButtonColor="#0052D9"
            confirmButtonText="確定"
            cancelButtonColor="#666"
            cancelButtonText="取消"
            :beforeClose="submit"
        > -->
        <el-dialog
            :title="dialogTitle"
            :show-close="true"
            :visible.sync="showDialog"
            append-to-body
            :close-on-click-modal="false"
            width="60%"
        >
            <el-form ref="form" :model="form" label-width="110px" style="padding: 24px;" v-if="!isEdit">
                <el-form-item label="客戶編號">
                    <!-- <el-select v-model="form.customer" placeholder="請選擇" @change="changeCustomer">
                        <el-option :label="item.customerId" :value="item.cusId" v-for="(item,index) in customerList" :key="index"></el-option>
                    </el-select> -->
                    <el-input v-model="customerId" placeholder="請輸入" @blur="changeCustomer"></el-input>
                </el-form-item>
                <el-form-item label="客戶姓" v-if="showCustomerInfo">
                    <el-input v-model="customerName" disabled></el-input>
                </el-form-item>
                <el-form-item label="正日" v-if="showCustomerInfo">
                    <el-input v-model="customerDay" disabled></el-input>
                </el-form-item>
                <el-form-item label="訂單編號" v-if="showOrderCode">
                    <el-select
                            v-model="form.orderCode"
                            placeholder="請選擇"
                            style="width: 100%;"
                            filterable
                            @change="(e) => changeOrderCode(e)"
                    >
                        <el-option
                                v-for="i in orders"
                                :key="i.orderCode"
                                :label="i.orderCode"
                                :value="i.orderCode"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="服裝用途" v-if="showDressAs">
                    <el-select
                            v-model="form.dressAs"
                            placeholder="請選擇"
                            style="width: 100%;"
                            filterable
                    >
                        <el-option
                                v-for="i in dressAsOptions"
                                :key="i.code"
                                :label="i.name"
                                :value="i.code"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="登記日期">
                    <el-input v-model="form.recordDate" disabled></el-input>
                </el-form-item>
                <el-form-item label="操作同事">
                    <el-input v-model="form.recorder" disabled></el-input>
                </el-form-item>
                <el-form-item label="是否定款">
                    <el-switch v-model="form.fixedStyle" active-text="是" inactive-text="否"></el-switch>
<!--                    <el-select v-model="form.fixedStyle" placeholder="請選擇">-->
<!--                        <el-option label="是" value="true"></el-option>-->
<!--                        <el-option label="否" value="false"></el-option>-->
<!--                    </el-select>-->
                </el-form-item>
                <el-form-item label="正日時間的前後" style="position: relative;">
                    <el-input v-model="form.extendDays" @input="changeExtendDay"></el-input>
                    <span class="day">天</span>
                </el-form-item>
            </el-form>

            <el-form ref="form" :model="form" label-width="120px" style="padding: 24px;" v-if="isEdit">
                <el-form-item label="客戶編號: ">
                    <span>{{customerCode}}</span>
                </el-form-item>
                <el-form-item label="客戶姓: ">
                    <span>{{ customerName }}</span>
                </el-form-item>
                <el-form-item label="正日時間的前後: " style="position: relative;">
                    <el-input v-model="form.extendDays"></el-input>
                    <span class="day">天</span>
                </el-form-item>
            </el-form>

            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    @click="showDialog = false"
                >
                    取消
                </el-button>
                <el-button
                    type="primary"
                    @click="submit"
                    :loading='submitLoading'
                >
                    確定
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import { Dialog,Toast,ImagePreview  } from 'vant';
export default {
    components:{Header},
    data(){
        return {
            loading:false,
            submitLoading:false,
            currentCalendar:'',
            year:new Date().getFullYear(),
            month:new Date().getMonth() +1,
            detailInfo:{},
            // customerList:[],
            calendarValue:'',
            showDialog:false,
            dialogTitle:'新增服裝預約',
            isEdit:false,
            clothingInfo:[
                {label:'服裝編號',key:'code'},
                {label:'類型',key:'typeName'},
                {label:'顔色',key:'color'},
                {label:'size',key:'size'},
                {label:'款式',key:'style'},
                {label:'狀態',key:'status'},
            ],
            tablefields:[
                {prop:'month',label:'月份',width:'60'},
                {prop:'weddingDate',label:'正日',width:'100'},
                {prop:'fixedStyle',label:'定款',width:'60'},
                {prop:'customerInfo',label:'客戶資料',width:'200'},
                {prop:'orderCode',label:'訂單編號',width:'80'},
                {prop:'dressAsName',label:'服裝用途',width:'100'},
                {prop:'recordDate',label:'登記日期',width:'120'},
                {prop:'recorder',label:'操作同事',width:'100'},
                // {prop:'startDate',label:'開始時間',width:'120'},
                // {prop:'endDate',label:'結束時間',width:'120'},
                {prop:'shootingDate',label:'拍攝時間',width:'156'},
                {prop:'btn',label:'',width:'100'},
            ],
            monthlyBookingInfo:[],
            tableData: [],
            initWidth: 80,
            customerName:'',
            customerCode:'',
            customerDay:'',
            form:{
                bizClothes:'',
                customer:'',
                recordDate:'',
                recorder: '',
                fixedStyle: true,
                extendDays:2,
                orderCode: '',
                orderType: null,
                dressAs: '',
            },
            customerId:'',
            day:'',
            userInfo:null,
            calendarRange: [],
            bookingCountThereafter: 0,
            showCustomerInfo: false,
            showOrderCode: false,
            showDressAs: false,
            orders: [],
            dressAsType: null,
            dressAsOptions: []
        }
    },
    watch: {
        currentCalendar(newValue, oldValue) {
            if(typeof newValue == 'object') {
                this.currentCalendar = this.formatMonth(newValue, '-');
                this.getDetailInfo();
            }
        }
    },
    computed:{
        handleCalendarSelect(){
            return function (date) {
                // console.log(date);
                let obj = this.monthlyBookingInfo.find(item => {
                    return item.date === date.day;
                })
                return obj?.hasBooking || false;
            }
        },
        diffCustomerLastDay() {
            return function (date) {
                let obj = this.monthlyBookingInfo.find(item => item.date.split(' ')[0] === date.day);
                if (obj) {
                    let { hasBooking, sameCustomerLastDay } = obj;
                    return hasBooking && !sameCustomerLastDay;
                }
                return false;
            }
        },
        diffCustomerNextDay() {
            return function (date) {
                let obj = this.monthlyBookingInfo.find(item => item.date === date.day);
                if (obj) {
                    let { hasBooking, sameCustomerNextDay } = obj;
                    return hasBooking && !sameCustomerNextDay;
                }
                return false;
            }
        },
        handlerCustomerInfo(){
            return function (row) {
                // console.log(date);
                /*
                let v1 = row.customer.bridegroomNameChinese ? row.customer.bridegroomNameChinese : row.customer.bridegroomNameEnglish;
                let v2 = row.customer.brideNameChinese ? row.customer.brideNameChinese : row.customer.brideNameEnglish;
                return `${v1}、${v2}、${row.customer.customerId}`
                */
                return row['baseInfoStr'];
            }
        },
        needlessWeek() {
            return function (date) {
                let firstDayOfThisWeek = this.monthlyBookingInfo.find(item => item.date === date.day)?.['firstDayOfThisWeek'];
                if (!firstDayOfThisWeek) {
                    firstDayOfThisWeek = date.day;
                }
                return firstDayOfThisWeek > this.calendarRange[1];
            }
        }
    },
    created(){
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.getCustomerList();
    },
    mounted(){
        this.form.recordDate = this.getCurrentDay();
        var year = new Date().getFullYear();
        var month = new Date().getMonth() +1 < 10 ? `0${new Date().getMonth() +1}` : new Date().getMonth() +1;
        this.currentCalendar = `${year}-${month}`;
        this.form.bizClothes = this.$route.query.id;
        this.getDetailInfo();
    },
    methods:{
        getDetailInfo(){
            let bizClothesId = this.$route.query.id;
            this.loading = true;
            this.$api.clothing.clothesBookingInfo({bizClothesId,month:this.currentCalendar}).then(res => {
                this.loading = false;
                let { code, data, msg } = res;
                if(code === 0) {
                    this.detailInfo = data.clothes || {};
                    this.tableData = data.bookingInfo;
                    this.monthlyBookingInfo = data.monthlyBookingInfo;
                    this.calendarRange = data.calendarRange;
                    this.bookingCountThereafter = data.bookingCountThereafter;
                } else {
                    this.$message.error(msg);
                }
            });
        },
        getCustomerList(){
            this.$api.customer.getCustomerList().then(res => {
                if(res.data) {
                    // this.customerList = res.data;
                }
            });
        },
        async getCustomer(customerId){
            if (!customerId) {
                return null;
            }
            let res = await this.$api.customer.getCustomerList({customerId});
            let customerList = res.data;
            if (customerList && customerList.length > 0) {
                return customerList[0];
            }
            return null;
        },
        async changeCustomer(){
            let obj = await this.getCustomer(this.customerId);
            this.form.customer = obj?.cusId || '';
            this.customerName = '';
            this.customerDay = '';
            if(obj) {
                this.customerName = obj['familyNames'];
                this.customerDay = obj.weddingDate ? obj.weddingDate.split(" ")[0] : '';

                let extData = obj['extData'];
                if (extData) {
                    if (!this.dressAsType) {
                        this.dressAsType = extData.dressAsType;
                    }
                    this.orders = extData.orders;
                }
                this.showCustomerInfo = true;
                this.showOrderCode = true;
            }
        },
        goPreview(){
            ImagePreview([this.detailInfo.image]);
            // if(this.detailInfo.image)  {
            //     ImagePreview([this.detailInfo.image]);
            // } else {
            //     Toast('暫無此服裝圖片');
            // }
        },
        handleAdd(){
            this.showDialog = true;
            this.dialogTitle = '新增服裝預約'
            this.isEdit = false;
            this.form = this.$options.data().form;
            this.form.recordDate = this.getCurrentDay();
            let userInfo = JSON.parse(localStorage.getItem('userInfo'));
            this.form.recorder = userInfo?.user?.name || '';
            this.form.bizClothes = this.$route.query.id;
            this.form.customer = null;
            this.customerId = '';
            this.customerName = '';
            this.customerDay = '';
            this.form.orderCode = null;
            this.form.orderType = null;
            this.form.dressAs = null;

            this.showCustomerInfo = false;
            this.showOrderCode = false;
            this.showDressAs = false;
        },
        handleEdit(row){
            this.showDialog = true;
            this.dialogTitle = '編輯';
            this.isEdit = true;
            console.log(row,'row')
            this.form.recordDate = this.getCurrentDay();
            this.form.bizClothes = this.$route.query.id;
            this.form.customer = row.customer.cusId;
            this.customerCode = row.customer.customerId;
            this.customerName = row.familyNames;
            this.form.id = row.id;
            this.form.extendDays = row.extendDays;
        },
        handleDelete(row){
            Dialog.confirm({
                title: '',
                message: `是否確定要刪除“${row['baseInfoStr']}”客戶的服裝預約信息`,
                confirmButtonColor:"#0052D9",
                confirmButtonText: '確定',
                cancelButtonColor:"#666",
                cancelButtonText: '取消'
            }).then(() => {
                    // on confirm
                    this.$api.clothing.deleteCloth({ids:row.id}).then(res => {
                        if(res.code == 0) {
                            this.$message.success('操作成功');
                            this.getDetailInfo();
                        }
                    });
                })
                .catch(() => {
                    // on cancel
                });
        },
        async submit(){
            if(!this.form.extendDays) {
                this.$message.warning('正日時間的前後不能為空');
                return;
            }
            if (!this.form.id) {
                if(!this.form.orderCode) {
                    this.$message.warning('訂單編號不能為空');
                    return;
                }
                if(!this.form.dressAs) {
                    this.$message.warning('服裝用途不能為空');
                    return;
                }
            }
            this.submitLoading = true;
            if (!this.form.customer) {
                await this.changeCustomer();
            }
            this.$api.clothing.saveOrEditCloth(this.form).then(res => {
                this.submitLoading = false;
                if(res.msg === '成功') {
                    this.currentCalendar = this.formatMonth(res.data, '-');
                    this.getDetailInfo();
                    this.$message.success('操作成功');
                    // done();
                    this.showDialog = false;
                } else if (res.code === 500) {
                    this.$message.error(res.msg);
                }
            });
        },
        objectSpanMethod({ row, rowIndex, columnIndex }) {
            let month = Number(row.customer?.weddingDate.split('-')[1]) ?? this.month;
            if (columnIndex === 0) {
                let sameMonthRows = this.tableData.filter(o => month === Number(o.customer?.weddingDate.split('-')[1]));
                if (sameMonthRows.every(o => o.customer?.weddingDate >= row.customer?.weddingDate)) {
                    return {rowspan: sameMonthRows.length, colspan: 1};
                } else {
                    return {rowspan: 0, colspan: 0};
                }
            }
        },
        getCurrentDay(){
            var now = new Date();
            var year = now.getFullYear();
            var month = now.getMonth() +1 < 10 ? `0${now.getMonth() +1}` : now.getMonth() +1;
            var day = now.getDate();
            return `${year}-${month}-${day}`;
        },
        changeTime(type){
            let year = this.currentCalendar.split('-')[0];
            let month = this.currentCalendar.split('-')[1]
            if(type == 'double-left'){
                this.currentCalendar = `${Number(year) - 1}-${month}`;
            }else if (type == 'double-right') {
                this.currentCalendar = `${Number(year) + 1}-${month}`;
            }else if (type == 'left') {
                if(month == 1) {
                    this.currentCalendar = `${Number(year) - 1}-12`;
                } else {
                    let monthValue = Number(month) - 1;
                    this.currentCalendar = `${year}-${monthValue < 10 ? '0'+ monthValue : monthValue}`;
                }
            }else if (type == 'right') {
                if(month == 12) {
                    this.currentCalendar = `${Number(year) + 1}-01`;
                } else {
                    let monthValue = Number(month) + 1;
                    this.currentCalendar = `${year}-${monthValue < 10 ? '0'+monthValue : monthValue}`;
                }
            }
            this.getDetailInfo();
        },
        changeExtendDay(v){
            v = ('' + v) // 第一步：轉成字符串
            v = v.replace(/[^\d]/g,'');
            console.log(v,'vvvvvvvvvv');
            this.form.extendDays = v;
        },
        formatDate(date) {
            if(typeof date == 'object') {
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const monthStr = month < 10 ? '0' + month : month;
                const dayStr = day < 10 ? '0' + day : day;
                return `${year}-${monthStr}-${dayStr}`;
            }
            return date;
        },
        formatMonth(date, delimiter) {
            let strArray = this.formatDate(date).split('-');
            return delimiter ? `${strArray[0]}${delimiter}${strArray[1]}` : `${strArray[0]}年${strArray[1]}月`;
        },
        changeOrderCode(orderCode) {
            let order = this.orders.find(o => o.orderCode === orderCode);
            let orderType = order.orderType;
            let dressAsType = this.dressAsType;
            if (order && order.orderType && dressAsType) {
                if (orderType !== this.form.orderType) {
                    this.form.orderType = orderType;
                    this.form.dressAs = null;
                }
                this.dressAsOptions = dressAsType[orderType] ?? [];
                this.showDressAs = true;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.header-info {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 24px 48px;
    margin-bottom: 24px;
    .logo {
        width: 200px;
        // height: 250px;
        margin-right: 48px;
    }
    .info {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        text-align: left;

        p {
            width: 50%;
        }
    }
}

.calendar {
    background-color: #fff;
    margin-bottom: 24px;
    border-radius: 8px;

    .calendar-header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 24px;
        .content {
            font-size: 18px;
            color: #000;
            margin: 0 30px;
        }
        // img:nth-child(1) {
        //     width: 22px;
        //     height: 22px;
        // }
        // img:nth-child(2) {
        //     width: 20px;
        //     height: 20px;
        // }
        // img:nth-child(3) {
        //     width: 18px;
        //     height: 18px;
        // }
        // img:nth-child(4) {
        //     width: 22px;
        //     height: 22px;
        // }
    }
}

.table {
    background-color: #fff;
    padding: 0 24px 24px;
    border-radius: 8px;

    .add-btn {
        float: right;
        margin: 24px 0 24px 0;
        background-color: #0052D9;
    }
}
.container-bottom {
    margin-bottom: 100px;
}

.cloth-detail {
    height: calc(100vh - 202px);
    overflow-y: auto;
}

::v-deep .el-select {
    display: block;
}

::v-deep .el-form-item__content {
    text-align: left;
}

::v-deep .el-calendar-day {
    padding: 0 !important;
    line-height: 85px;

    p {
        margin: 0;
        font-size: 14px;
        height: 100%;
    }
}

::v-deep .isSelect {
    background: #0078D7;
    color: #fff;
}

::v-deep .diffCustomerLastDay {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
}
::v-deep .diffCustomerNextDay {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}

::v-deep .el-calendar__header {
    display: none;
}

::v-deep .el-calendar-table td.is-selected {
    background-color: #fff;
}

::v-deep .el-calendar-table td:is(.current) .el-calendar-day:hover {
    background-color: #fff;
}
::v-deep .el-calendar-table td:not(.current) .el-calendar-day:hover {
    background-color: #F5F5F5;
}

.day {
    position: absolute;
    top: 0;
    right: 12px;
}
::v-deep tr.el-calendar-table__row:has(.needlessWeek) {
    display: none;
}
::v-deep .el-calendar-table:not(.is-range) td.prev {
    color: #2c3e50 !important;
    background-color: #F5F5F5;
}
::v-deep .el-calendar-table:not(.is-range) td.next {
    color: #2c3e50 !important;
    background-color: #F5F5F5;
}
::v-deep .current-calendar {
    .el-input__prefix {
        display: none;
    }
    .el-input__inner {
        border: none;
        font-size: 16px;
        color: #333;
        padding: 0;
    }
}
</style>
