import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router);
import login from "@/views/login.vue";
import home from "@/views/home.vue";
import clothing from "@/views/clothing/clothing.vue";
import clothDetail from "@/views/clothing/clothDetail.vue";
import customer from "@/views/customer/customer.vue";
import customerDetail from "@/views/customer/customerDetail.vue";
import calendar from "@/views/calendar.vue";
// const requireRouter = require.context('./module/', true, /\.js$/);
let routeData = [
    {
        // 登陆
        path: '*',
        component: login,
        name: 'login',
        children: [
            {
                path: '/login',
                name: 'login',
                component: login,
            },
        ],
        meta: {
            title: '登录',
            showTabbar:false
        }
    },
    {
        // 首页
        path: '/home',
        component: home,
        name: 'home',
        children: [
            {
                path: '/home',
                name: 'home',
                component: home,
            },
        ],
        meta: {
            title: '',
            showTabbar:false
        }
    },
    {
        // 服装详情 
        path: '/clothDetail',
        component: clothDetail,
        name: 'clothDetail',
        children: [
            {
                path: '/clothDetail',
                name: 'clothDetail',
                component: clothDetail,
            },
        ],
        meta: {
            title: '',
            showTabbar:false
        }
    },
    {
        // 服装信息查询 
        path: '/clothing',
        component: clothing,
        name: 'clothing',
        children: [
            {
                path: '/clothing',
                name: 'clothing',
                component: clothing,
            },
        ],
        meta: {
            title: '',
            showTabbar:false
        }
    },
    {
        // 顾客资料查询
        path: '/customer',
        component: customer,
        name: 'customer',
        children: [
            {
                path: '/customer',
                name: 'customer',
                component: customer,
            },
        ],
        meta: {
            title: '',
            showTabbar:false
        }
    },
    {
        // 顾客资料详情
        path: '/customerDetail',
        component: customerDetail,
        name: 'customerDetail',
        children: [
            {
                path: '/customerDetail',
                name: 'customerDetail',
                component: customerDetail,
            },
        ],
        meta: {
            title: '',
            showTabbar:false
        }
    },
    {
        // 日历统计
        path: '/calendar',
        component: calendar,
        name: 'calendar',
        children: [
            {
                path: '/calendar',
                name: 'calendar',
                component: calendar,
            },
        ],
        meta: {
            title: '',
            showTabbar:false
        }
    },
];
console.log(routeData,'routeData')
const router = new Router({
    routes:routeData
}) 
// 获取原型对象push函数
const originalPush = Router.prototype.push

// 获取原型对象replace函数
const originalReplace = Router.prototype.replace

// 修改原型对象中的push函数
Router.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
Router.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}

export default router;