import http from '@/utils/httpRequest';

export default {
    async getCustomerList(params) {
        let {data} = await http({
            url: '/goddess/customer/listAll.json',
            method: 'post',
            data: params,
        });
        return data;
    },
    async getCustomerInfoByCode(params) {
        let {data} = await http({
            url: '/goddess/customer/viewByCode.json',
            method: 'post',
            data: params,
        });
        return data;
    },
    async viewOrderA(params) {
        let {data} = await http({
            url: `/goddess/orderMain/viewOrderA.json?orderChildId=${params}`,
            method: 'get',
            data: params,
        });
        return data;
    },
    async viewOrderB(params) {
        let {data} = await http({
            url: `/goddess/orderMain/viewOrderB.json?orderChildId=${params}`,
            method: 'get',
            data: params,
        });
        return data;
    },
    async saveOrderA(params) {
        let {data} = await http({
            url: `/goddess/orderMain/saveOrderA.json`,
            method: 'post',
            data: params,
            type: 'json'
        });
        return data;
    },
    async saveClothesBookingInfo(params) {
        let {data} = await http({
            url: `/goddess/clothesBookingInfo/saveClothesBookingInfo.json`,
            method: 'post',
            data: params,
            type: 'json'
        });
        return data;
    },
    async removeClothesBookingInfo(params) {
        let {data} = await http({
            url: `/goddess/clothesBookingInfo/removeClothesBookingInfo.json`,
            method: 'post',
            data: params,
            type: 'json'
        });
        return data;
    },
    async saveOrderB(params) {
        let {data} = await http({
            url: `/goddess/orderMain/saveOrderB-main.json`,
            method: 'post',
            data: params,
            type: 'json'
        });
        return data;
    },
    async saveRentInfoOfOrderB(params) {
        let {data} = await http({
            url: `/goddess/orderMain/saveOrderB-rentInfo.json`,
            method: 'post',
            data: params,
            type: 'json'
        });
        return data;
    },
    async saveRentInfo(params) {
        let {data} = await http({
            url: `/goddess/orderMain/saveOrderB-rentInfo.json`,
            method: 'post',
            data: params,
            type: 'json'
        });
        return data;
    }
}
