import http from '@/utils/httpRequest';

export default {
    async getClothingList(params) {
        let {data} = await http({
            url: '/goddess/bizClothes/list.json',
            method: 'post',
            data: params,
        });
        return data;
    },
    async getClothDetail(params) {
        let {data} = await http({
            url: `/goddess/bizClothes/view.json?id=${params}`,
            method: 'get',
            data: params,
        });
        return data;
    },
    async clothesBookingInfo(params) {
        let {data} = await http({
            url: '/goddess/clothesBookingInfo/listByClothesId.json',
            method: 'post',
            data: params,
        });
        return data;
    },
    async saveOrEditCloth(params) {
        let {data} = await http({
            url: '/goddess/clothesBookingInfo/saveOrEdit.json',
            method: 'post',
            data: params,
        });
        return data;
    },
    async deleteCloth(params) {
        let {data} = await http({
            url: '/goddess/clothesBookingInfo/delete.json',
            method: 'post',
            data: params,
        });
        return data;
    },
    async viewByCode(params) {
        let {data} = await http({
            url: `/goddess/bizClothes/viewByCode.json?code=${params}`,
            method: 'get',
            data: params,
        });
        return data;
    },
    async viewClotheStatus(orderCode, days) {
        let url = `/goddess/bizClothes/viewClotheStatus.json?orderCode=${orderCode}`;
        if (days) {
            url += `days=${days}&`;
        }
        let {data} = await http({
            url: url,
            method: 'get',
        });
        return data;
    },
    async addRentBookingInfo(params) {
        let {data} = await http({
            url: '/goddess/clothesBookingInfo/addRentBookingInfo.json',
            method: 'post',
            data: params,
            type: 'json'
        });
        return data;
    },
    async updateRentBookingInfo(params) {
        let {data} = await http({
            url: `/goddess/clothesBookingInfo/updateRentBookingInfo.json`,
            method: 'post',
            data: params,
            type: 'json'
        });
        return data;
    }
};
