<template>
    <el-dialog
        :title="isClothing ? '服裝信息' : '查詢衣服'"
        :show-close="true"
        :visible.sync="visible"
        append-to-body
        :close-on-click-modal="false"
        width="60%"
    >
        <div v-if="!isClothing">
            <div class="search">
                <el-input v-model="value" placeholder="請輸入服裝編號" style="width: 50%;margin-bottom: 16px;display: block;"></el-input>
                <el-button type="primary" style="width: 30%;" @click="goSearch" :loading='loading'>確定</el-button>
            </div>

            <div class="sweep" @click="goScan">
                <svg-icon
                    icon-class="scan"
                    style="font-size: 16px;"
                />
                <p>掃一掃</p>
            </div>
        </div>

        <div class="list" v-if="isClothing">
            <div class="list-item">
                <img :src="clothingDetail.image" class="logo" v-if="clothingDetail.image" referrer='no-referrer'>
                <img src="../../../assets/no-img.png" class="logo" v-else>
                <div class="info">
                    <div v-for="(info,index1) in clothingInfo" :key="index1">
                        <span>{{ info.label }}：</span>
                        <span>{{ clothingDetail[info.key] }}</span>
                    </div>
                </div>
            </div>
            <div style="display: flex;align-items: center;margin-top: 24px;" v-if="isExternal">
                <span style="margin-right: 24px;">是否定款</span>
                <el-radio v-model="form.fixedStyle" :label="true">是</el-radio>
                <el-radio v-model="form.fixedStyle" :label="false">否</el-radio>
            </div>
        </div>
        <span
            v-if="isClothing"
            slot="footer"
            class="dialog-footer"
        >
            <el-button @click="visible = false">
                取消
            </el-button>
            <el-button
                type="primary"
                @click="submit"
                :loading='loading'
            >
                確定
            </el-button>
        </span>
        <ScanComponent ref="scanComponent" @onDecode="onDecode"/>
    </el-dialog>
</template>

<script>
import ScanComponent from '@/components/ScanComponent.vue'
export default {
    components:{ScanComponent},
    data(){
        return {
            loading:false,
            value:'',
            visible:false,
            isClothing:false,
            isExternal:false,
            clothingDetail:{},
            clothingInfo:[
                {label:'服裝編號',key:'code'},
                {label:'類型',key:'typeName'},
                {label:'顏色',key:'color'},
                {label:'size',key:'size'},
                {label:'款式',key:'style'},
                {label:'狀態',key:'status'},
            ],
            form:{
                bizClothes:"",
                dressAs:"",
                extendDays: null,
                fixedStyle:true,
                orderCode:'',
                orderType:''
            }
        }
    },
    methods:{
        open(orderType, orderCode, dressAs, rentType, clothesUseStatus){
            let userInfo = JSON.parse(localStorage.getItem('userInfo'));
            this.visible =true;
            this.value = '';
            this.isClothing = false;
            this.form.orderType = orderType;
            this.form.orderCode = orderCode;
            this.form.dressAs = dressAs;
            this.form.recorder = userInfo.user.name;
            if (rentType) {
                this.form.rentType = rentType;
            }
            if (clothesUseStatus) {
                this.form.clothesUseStatus = clothesUseStatus;
                this.form.fixedStyle = clothesUseStatus === 'determinate';
            }
        },
        goSearch(){
            this.loading = true;
            this.$api.clothing.viewByCode(this.value).then(res => {
                this.loading = false;
                if(res.code === 0 && res.data) {
                    this.isClothing = true;
                    this.clothingDetail = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        submit(){
            this.form.bizClothes = this.clothingDetail.id;
             this.loading = true;
            this.$api.customer.saveClothesBookingInfo(this.form).then(res => {
                this.loading = false;
                if(res.code === 0) {
                    this.visible = false;
                    this.$emit('submit',this.clothingDetail.code);
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        goScan(){
            this.$refs.scanComponent.goScan();
        },
        onDecode(result){
            this.value = result;
            this.goSearch();
        }
    }
}
</script>

<style lang="scss" scoped>
.search {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    margin-bottom: 24px;
    padding: 16px 0;
}

.sweep {
    background-color: #f5f5f5;
    text-align: center;
    padding: 32px 0 16px 0;
    img {
        width: 30px;
        height: 30px;
    }

    p {
        font-size: 16px;
        color: #333;
    }
}

.list {
    padding: 0 32px;
    .list-item {
        position: relative;
        display: flex;
        border-bottom: 1px solid #e5e5e5;
        padding-top: 12px;
        padding-bottom: 12px;

        .logo {
            width: 141px;
            height: 188px;
            margin-right: 24px;
        }
        .info {
            text-align: left;
        }
    }

    .list-item:last-child {
        border-bottom: none;
    }

    .info {
        div {
            margin-bottom: 16px;
        }
    }
}
</style>
