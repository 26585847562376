<template>
    <div class="header">
        <img src="../assets/left_icon.png" @click="goBack">
        <div class="title">{{ title }}</div>
        <div>
            <slot name="right" />
        </div>
    </div>
</template>

<script>
export default {
    props:{
        title:{
            type: String,
            default: '自定義標題'
        },
        callback:{
            type: [Boolean,Function],
            default: false
        }
    },
    data(){
        return {

        }
    },
    methods:{
        goBack(){
            if(this.callback) {
                this.callback();
            } else {
                this.$router.go(-1);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    position: relative;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px 24px 24px;

    img {
        width: 20px;
        height: 20px;
    }

    .title {
        font-size: 16px;
        color: #333;
        font-weight: 600;
    }
}
</style>
