import http from '@/utils/httpRequest';

export default {
    async ossPolicy(params) {
        let {data} = await http({
            url: `/aliyun/oss/policy`,
            method: 'get',
            data: params,
        });
        return data;
    },
}