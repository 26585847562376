import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: { 
    coreDict:[]
  },
  mutations: { 
    getCoreDict (state,params) { 
      state.coreDict = params;
    }
  },
  getters: { 
    getCount: state => state.count 
  },
})
 
export default store