<template>
    <div class="home">
        <div class="opration-btn">
            <div class="btn-item" v-for="(item,index) in oprationMenu" :key="index" @click="goLink(item)">
                {{ item.label }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            oprationMenu:[
                {label:'服裝查詢',path:'/clothing'},
                {label:'顧客資料查詢',path:'/customer'},
                {label:'日曆統計',path:'/calendar'},
            ]
        }
    },
    mounted(){
        this.getCoreDict();
    },
    methods:{
        goLink(item){
            this.$router.push(item.path);
        },
        getCoreDict(){
            this.$api.core.getCoreDict({type:'clothes_type'}).then(res => {
                if(res.data) {
                    localStorage.setItem('coreDict', JSON.stringify(res.data));
                    // this.$store.commit('getCoreDict',res.data);
                    // console.log(this.$store.state.coreDict,'coreDict')
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.home {
    background: #fff;

    .opration-btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
        .btn-item {
            width: 50%;
            height: 88px;
            line-height: 88px;
            border-radius: 8px;
            border: 1px solid #3288FD;
            margin-bottom: 32px;
            color: #3288FD;
        }
    }
}
</style>
