import Vue from 'vue'
import App from './App.vue'
import router from './router';
import usingApi from '@/api/index';
import Vant from 'vant';
import ElementUI from 'element-ui';
import { Toast } from 'vant';
//工具类方法
import { getUtils } from '@/utils';
import 'vant/lib/index.css';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/svg'; // icon
import store from './store'
import usingComponents from '@/components/index';


Vue.use(Vant);
Vue.use(ElementUI);
Vue.use(Toast);
Vue.use(usingApi);
Vue.use(usingComponents);
Vue.use(getUtils());
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
