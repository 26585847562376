<template>
    <div class="externalRental container-bottom">
        <div v-if="$parent.pageType == 'externalRental'">

            <div class="container">
                <div class="title">訂單簡介
                    <svg-icon
                        icon-class="edit-icon"
                        style="font-size: 11px;float: right;"
                        @click.stop="editOrder()"
                    />
                </div>
                <!-- <div style="text-align: end;margin-bottom: 24px;">
                    <el-button type="primary" class="edit_btn" @click="editOrder">編輯</el-button>
                </div> -->
                <div class="detail-info">
                    <div class="info-item" v-for="(item,index) in detailInfo" :key="index">
                        <span class="label">{{ item.label }}</span>
                        <span :class="{'value':true,'detail-info-value':true,'show-all':item.showAll}">
                            <span class="text" :id="item.key">{{ item.value }}</span>
                            <span v-show="item.isExceed" class="more" @click="handleShowAll(index,item.key)">{{ item.showAll ? '收起' : '展開' }}</span>
                        </span>
                        <!-- <span class="value">{{ item.value }}</span> -->
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="title">預約信息</div>
                <div style="display: flex;justify-content: flex-end">
                    <el-button type="primary" @click="reservation">預約</el-button>
                    <el-button type="primary" @click="fitting" :loading='fittingLoading'>Fitting</el-button>
                </div>
                <el-table
                    :data="tableData"
                    border
                    style="width: 100%; margin-top: 20px;"
                >
                    <template v-for="(item,index) in tablefields">
                        <el-table-column
                            :prop="item.prop"
                            :label="item.label"
                            :key="index+'tablefields'"
                            :width="item.width ? item.width : item.autoWidth ? initWidth : ''"
                            :fixed="item.fixed"
                        >
                            <template slot-scope="scope">
                                <span v-if="item.prop == 'time'">
                                    <span @click="goReservation(scope.row)" style="color: #0052D9;">
                                        {{scope.row.time}}
                                    </span>
                                </span>
                                <span v-else-if="item.prop == 'btn'">
                                    <span @click="goEdit(scope.row)" style="color: #0052D9;">
                                        編輯
                                    </span>
<!--                                    TODO: 240423 暫時注釋-->
<!--                                    <span v-if="scope.row.type === 'booking' && scope.row.latest === true">-->
<!--                                        <span @click="lock(scope.row)" v-if="scope.row.locked === false" style="color: #ea0a0a;">-->
<!--                                            鎖定-->
<!--                                        </span>-->
<!--                                        <span v-else>已鎖定</span>-->
<!--                                    </span>-->
                                </span>
                                <span  v-else>{{ scope.row[item.prop] }}</span>
                            </template>
                        </el-table-column>
                    </template>
                </el-table>
            </div>
        </div>

        <FittingList  ref="fittingList" v-if="$parent.pageType == 'fittingList'" @fittingSuccess="fittingSuccess"/>
        <el-dialog
            title="預約信息"
            :show-close="false"
            :visible.sync="visible"
            append-to-body
            :close-on-click-modal="false"
            width="60%"
        >
            <div>
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="時間">
                        <el-date-picker
                            type="datetime"
                            placeholder="選擇時間"
                            v-model="form.time"
                            style="width: 100%;"
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :editable="false"
                            :clearable="false"
                            :disabled="reservationId != null"
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="備註">
                        <el-input type="textarea" v-model="form.remarks" :autosize="{minRows:6}"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    @click="visible = false"
                >
                    取消
                </el-button>
                <el-button
                    type="primary"
                    @click="submit"
                    :loading="submitLoading"
                >
                    確定
                </el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="編輯訂單"
            :show-close="false"
            :visible.sync="orderVisible"
            append-to-body
            :close-on-click-modal="false"
            width="70%"
        >
            <div>
                <el-form ref="form" :model="editOrderForm" label-width="100px">
                    <el-form-item label="婚宴優惠券">
                        <el-input v-model="editOrderForm.coupon"></el-input>
                    </el-form-item>
                    <el-form-item label="禮服數目">
                        <el-input v-model="editOrderForm.clothesCount"></el-input>
                    </el-form-item>
                    <el-form-item label="營業員">
                        <el-select
                            v-model="editOrderForm.staffs"
                            multiple
                            :multiple-limit = "4"
                            placeholder="請選擇"
                            style="width: 100%;"
                            >
                                <el-option
                                    v-for="item in staffType"
                                    :key="item.name"
                                    :label="item.name"
                                    :value="item.value"
                                >
                            </el-option>
                        </el-select>
                        <!-- <el-input v-model="orderForm.orderInfo.staffName"></el-input> -->
                    </el-form-item>
                    <el-form-item label="總額">
                        <el-input-number v-model="editOrderForm.amount" :controls="false" :min="0" :precision="2" style="width: 100%;"></el-input-number>
                    </el-form-item>
                    <el-form-item label="備註">
                        <el-input type="textarea" v-model="editOrderForm.remarkInvoice" :autosize="{minRows:6}"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    @click="orderVisible = false"
                >
                    取消
                </el-button>
                <el-button
                    type="primary"
                    @click="submitOrder"
                    :loading="submitLoading"
                >
                    確定
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import FittingList from './FittingList.vue'
export default {
    components:{FittingList},
    props:{
        id:{
            typeof:String | Number,
            default:0
        }
    },
    data(){
        return {
            submitLoading:false,
            fittingLoading:false,
            type:'',
            detailInfo:[
                {label:'婚宴日期',key:'weddingDate',value:'',showAll:false},
                // {label:'宴會/舉行地點',key:'weddingLocation',value:'',showAll:false},
                {label:'外租套餐',key:'rentPackage',value:'',showAll:false},
                // {label:'證婚時間',key:'witnessDate',value:'',showAll:false},
                {label:'禮服數目',key:'clothesCount',value:'',showAll:false},
                {label:'婚宴優惠券',key:'coupon',value:'',showAll:false},
                {label:'單據編號 ',key:'orderBId',value:'',showAll:false},
                {label:'營業員',key:'staffName',value:'',showAll:false},
                {label:'總額',key:'amount',value:'',showAll:false},
                {label:'備注',key:'remarkInvoice',value:'',showAll:false}
            ],
            tableData:[],
            tablefields:[
                {prop:'numberOfBooking',label:'次數',width:'120'},
                {prop:'time',label:'時間',minWidth:'150'},
                {prop:'typeName',label:'類型',minWidth:'150'},
                {prop:'remarks',label:'備註',minWidth:'150'},
                // TODO: 240423 暫時注釋
                // {prop:'btn',label:'',minWidth:'95', width: '95', fixed:'right'},
                {prop:'btn',label:'',minWidth:'60', width: '60', fixed:'right'}
            ],
            visible:false,
            form:{
                orderCode:'',
                time:'',
                remarks:'',
                type:'',
                locked: false
            },
            orderVisible:false,
            orderForm:{
                orderMain:{
                    weddingDate:''
                },
                orderInfo:{}
            },
            editOrderForm:{
                amount:0,
                clothesCount:'',
                coupon:'',
                orderCode:'',
                remarkInvoice:'',
                staffs:[]
            },
            staffType:[],
            reservationId:null
            // weddingLocation:[]
        }
    },
    mounted(){
        this.getInfo();
        let coreDict = JSON.parse(localStorage.getItem('coreDict'));
        this.staffType = coreDict.filter(item => item.type === 'staff' && item.name && item.name.replace('&nbsp;', '').replace(' ', '') !== '');
        // this.weddingLocation = this.$store.state.coreDict.filter(item => {return item.type === 'wedding_location'});
    },
    methods:{
        getInfo() {
            // this.id = 'B2399';
            this.$api.customer.viewOrderB(this.id).then(res => {
                if(res.code == 0 && res.data) {
                    this.orderForm = JSON.parse(JSON.stringify(res.data));
                    this.orderForm.orderInfo.staffs = this.orderForm.orderInfo.staffs.reduce((initArr,item) => {
                        initArr.push(item.name);
                        return initArr;
                    },[])
                    if(res.data.orderInfo.staffs) {
                        this.editOrderForm.staffs = res.data.orderInfo.staffs.reduce((initArr,item) => {
                            initArr.push(item.value);
                            return initArr;
                        },[])
                    }
                    this.detailInfo.forEach((element,index) => {
                        if(res.data.orderInfo[element.key]) {
                            element.value = res.data.orderInfo[element.key]
                        }
                        if(res.data.orderMain[element.key]) {
                            element.value = res.data.orderMain[element.key]
                        }
                        if(element.key == 'staffName') {
                            // 處理營業員
                            element.value = res.data.orderInfo.staffs.reduce((initArr,item) => {
                                initArr.push(item.name);
                                return initArr;
                            },[]);
                            element.value = element.value.join(",");
                        }
                        this.$nextTick(() => {
                            this.isShowMore(element.key,index);
                        })
                    })
                    this.tableData = res.data['historyInfos'] || [];

                    // this.reservationInfo.forEach(element => {
                    //     if(element.key) {
                    //         element.value = res.data.orderInfo[element.key];
                    //     }
                    // })
                }
            })
        },
        reservation() {
            this.visible = true;
            this.form.time = this.getCurrentDay();
            this.form.remarks = '';
            this.form.type = 'booking';
            this.reservationId = null;
        },
        goEdit(row) {
            this.visible = true;
            this.form.time = row.time;
            this.form.remarks = row.remarks;
            this.reservationId = row.id;
        },
        lock(row) {
            this.reservationId = row.id;
            this.form.remarks = row.remarks;
            this.form.locked = true;
            this.submit();
        },
        submit(){
            this.form.orderCode = this.id;
            this.submitLoading = true;
            let ajaxName = this.reservationId ? 'updateRentBookingInfo' : 'addRentBookingInfo';
            let params = this.reservationId ? { id:this.reservationId, remarks:this.form.remarks, locked:this.form.locked} : JSON.parse(JSON.stringify(this.form));
            this.$api.clothing[ajaxName](params).then(res => {
                this.submitLoading = false;
                if(res.code == 0) {
                    this.visible = false;
                    this.$message.success('操作成功');
                    this.getInfo();
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        fitting() {
            this.fittingLoading = true;
            this.$api.clothing.viewClotheStatus(this.id).then(res => {
                this.fittingLoading = false;
                if(res.code === 0) {
                    this.$parent.pageType = 'fittingList'
                    this.$nextTick(() => {
                        this.$refs.fittingList.handleTableData(res.data);
                    })
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        fittingSuccess(){
            this.$parent.pageType = 'externalRental';
            this.getInfo();
        },
        editOrder(){
            this.orderVisible = true;
            this.editOrderForm = {
                amount:this.orderForm.orderInfo.amount,
                clothesCount:this.orderForm.orderInfo.clothesCount,
                coupon:this.orderForm.orderInfo.coupon,
                orderCode:this.id,
                remarkInvoice:this.orderForm.orderInfo.remarkInvoice,
                staffs:this.editOrderForm.staffs
            };
        },
        submitOrder(){
            let params = JSON.parse(JSON.stringify(this.editOrderForm));
            params.staffs = params.staffs.map(item => {return {value:item}})
            this.submitLoading = true;
            this.$api.customer.saveOrderB(params).then(res => {
                this.submitLoading = false;
                if(res.code == 0) {
                    this.orderVisible = false;
                    this.$message.success('操作成功');
                    this.getInfo();
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        goReservation(row){
            this.$parent.pageType = 'reservation';
            this.$nextTick(() => {
                this.$parent.$refs.reservation.activeName  = row.type === 'fitting' ? 'fitting' : 'reservation';
            })
        },
        getCurrentDay(){
            return new Date().toLocaleString().replaceAll("/", "-");
        },
        handleShowAll(index,key){
            this.detailInfo[index].showAll = !this.detailInfo[index].showAll;
            let dom = document.getElementsByClassName('detail-info-value');
            let childDom = document.getElementById(key);
            let moreDom = document.getElementsByClassName('more');
            console.log(moreDom,'moreDom')
            if(this.detailInfo[index].showAll) {
                dom[index].style.whiteSpace = 'normal';
                childDom.style.whiteSpace = 'normal';
                childDom.style.maxWidth = '100%';
                childDom.style.display = 'inline';
                moreDom[index].style.position = 'static'
            } else {
                dom[index].style.whiteSpace = 'nowrap';
                childDom.style.whiteSpace = 'nowrap';
                childDom.style.maxWidth = '80%';
                childDom.style.display = 'inline-block';
                moreDom[index].style.position = 'absolute'
            }
        },
        isShowMore(key,index){
            this.$nextTick(() => {
                let dom = document.getElementById(key);
                let domArr = document.getElementsByClassName('detail-info-value');
                if(dom) {
                    let parentWidth = (domArr[0].offsetWidth - 12)*0.8;
                    let childWidth = dom.getBoundingClientRect().width;
                    if(parentWidth - childWidth < 5) {
                        let obj = this.detailInfo.find(item => {return item.key === key});
                        obj.isExceed = true;
                        this.$set(this.detailInfo,index,obj);
                    }

                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    background-color: #fff;
    margin-top: 24px;
    padding: 24px;

    .title {
        color: #333;
        font-weight: 600;
        font-size: 16px;
        padding-bottom: 24px;
        border-bottom: 1px solid #e5e5e5;
        text-align: center;
        margin-bottom: 24px;
        margin-left: 20px;
    }

    .edit_btn {
    }

    .detail-info {
        display: flex;
        flex-wrap: wrap;
        padding-left: 24px;
        .info-item {
            position: relative;
            width: calc(50% - 8px);
            display: flex;
            margin-bottom: 16px;
            margin-right: 16px;
            > span {
                display: inline-block;
                padding: 5px 0;
                // height: 30px;
                // line-height: 30px;
                font-size: 14px;
                border: 1px solid #e5e5e5;
            }
            .label {
                width: 35%;
                border-right: none;
                color: #666;
                background-color: #F8F8F8;
            }
            .value {
                position: relative;
                flex: 1;
                color: #333;
                padding: 5px 6px;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
                display: inline-block;
                white-space: nowrap;
                .text {
                    max-width: 80%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    white-space: nowrap;
                }
            }
            .more {
                position: absolute;
                top: 6px;
                right: 10px;
                color: rgb(0, 82, 217);
                font-size: 12px;
                border: none !important;
                margin-left: 10px;
            }
        }
    }

    .detail-info > div:nth-child(even) {
        margin-right: 0;
    }
}
.container-bottom {
    margin-bottom: 100px;
}

::v-deep .el-input-number .el-input__inner {
    text-align: left;
}
</style>
