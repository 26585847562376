<template>
    <div>
        <Header title="服裝信息">
            <template #right>
                <svg-icon
                    icon-class="scan"
                    style="font-size: 14px;"
                    @click="goScan"
                />
            </template>
        </Header>
        <div class="container"  v-loading="loadingPage" element-loading-text="加載中">
            <div class="filter">
                <el-form ref="form" :model="form" label-width="60px" :inline="true">
                    <el-form-item label="編號：">
                        <el-input v-model="form.code" placeholder="請輸入服裝編號"></el-input>
                    </el-form-item>
                    <el-form-item label="類型：">
                        <el-select v-model="form.type" placeholder="請選擇類型" clearable>
                            <el-option :label="item.name" :value="item.value" v-for="(item,index) in clothingType" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="顏色：">
                        <el-input v-model="form.color" placeholder="請輸入顏色"></el-input>
                    </el-form-item>

                    <el-form-item label="款式：" class="style">
                        <el-input v-model="form.style" placeholder="請輸入款式"></el-input>
                    </el-form-item>
                    <el-form-item label="size：">
                        <el-input v-model="form.size" placeholder="請輸入size"></el-input>
                    </el-form-item>
                    <el-form-item label="狀態：">
                        <el-select v-model="form.status" placeholder="請選擇狀態">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="啟用" value="啟用"></el-option>
                            <el-option label="待停用" value="待停用"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div style="display: flex;justify-content: flex-end;margin-right: 24px;">
                    <el-button type="primary" @click="onSearch" :loading="loading">查詢</el-button>
                </div>
            </div>
            <div class="list" @scroll="scrollHandle" v-if="dataList.length > 0" ref="list">
                <div class="list-item" v-for="(item,index) in dataList" :key="index" @click="goDetail(item)">
                    <img :src="item.image" class="logo" v-if="item.image" referrer='no-referrer'>
                     <img src="../../assets/no-img.png" class="logo" v-else>
                    <div class="info">
                        <p v-for="(info,index1) in clothingInfo" :key="index1">
                            <span>{{ info.label }}：</span>
                            <span>{{ item[info.key] }}</span>
                        </p>
                    </div>
                    <img src="../../assets/right_icon.png" class="right_icon">
                </div>
            </div>
            <Empty v-else imagew="150" style="margin-top: 15%" />
        </div>
        <ScanComponent ref="scanComponent" @onDecode="onDecode"/>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Empty from '@/components/Empty.vue';
import ScanComponent from '@/components/ScanComponent.vue';
export default {
    components:{Header,Empty,ScanComponent},
    data(){
        return{
            loading:false,
            form:{
                page: 0,
                limit: 10,
                code:'',
                type:'',
                color:'',
                style:'',
                size:'',
                status:''
            },
            dataList:[],
            clothingInfo:[
                {label:'服裝編號',key:'code'},
                {label:'類型',key:'typeName'},
                {label:'顏色',key:'color'},
                {label:'size',key:'size'},
                {label:'款式',key:'style'},
                {label:'狀態',key:'status'},
            ],
            clothingType:[],
            isMax:false,
            loadingPage:false
        }
    },
    mounted(){
        this.clothingType = JSON.parse(localStorage.getItem('coreDict')).filter(item => {return item.type === 'clothes_type'});
        let clothData = JSON.parse(sessionStorage.getItem('clothData'));
        if(clothData) {
            this.form = clothData.form;
            this.dataList = clothData.data;
            this.$nextTick(() =>{
                this.$refs.list.scrollTop = clothData.scrollTop
                sessionStorage.removeItem("clothData");
            });
        } else {
            this.loadingPage = true;
            this.getList();
        }
    },
    methods:{
        getList(successCallback, errorCallBack){
            this.form.page++;
            if(this.form.page === 1) {
                this.dataList = [];
            }
            this.form.withoutPause = true;
            this.$api.clothing.getClothingList(this.form).then(res => {
                this.loading = false;
                this.loadingPage = false;
                if(res.code === 0 && res.data) {
                    this.dataList = this.dataList.concat(res.data);
                    this.isMax = this.dataList.length >= res.count;
                    if(successCallback) successCallback();
                } else {
                    if(errorCallBack) errorCallBack();
                }
            })
        },
        scrollHandle(e){ // 滾動加載
            let bottom = e.target.scrollHeight -  e.target.scrollTop - e.target.clientHeight;
            if(bottom < 40){
                this.$nextTick(()=>{
                    if(this.isMax) return;
                    this.getList();
                })
            }
        },
        onSearch(callback){
            this.dataList = [];
            this.form.page = 0;
            this.form.limit = 10;
            this.isMax = false;
            this.loading = true;
            this.getList(callback);
        },
        goDetail(item){
            let obj = {form:this.form,data:this.dataList,scrollTop:this.$refs.list.scrollTop}
            sessionStorage.setItem('clothData', JSON.stringify(obj));
            this.$router.push(`/clothDetail?id=${item.id}`);
        },
        goScan(){
            this.$refs.scanComponent.goScan();
        },
        onDecode(result){
            this.form.code = result;
            this.$api.clothing.viewByCode(result).then(res => {
                let { code, data } = res;
                if(code === 0 && data) {
                    this.$router.push(`/clothDetail?id=${data.id}`);
                } else {
                    this.onSearch();
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
// ::v-deep .el-form-item__label {
//     display: ;
// }
::v-deep .el-input,.el-select {
    width: 160px;
}

.filter {
    padding-top: 24px;

    .el-form {
        display: flex;
        flex-wrap: wrap;

        .el-form-item {
            display: flex;
        }
    }
}

.list {
    padding: 0 32px;
    height: calc(100vh - 370px);
    overflow: auto;
    .list-item {
        position: relative;
        display: flex;
        border-bottom: 1px solid #e5e5e5;
        padding: 24px 0;

        .logo {
            width: 145px;
            height: 193.5px;
            margin-right: 24px;
        }
        .info {
            text-align: left;

            > p {
                margin: 12px 0;
            }

            span {
                font-size: 12px;
            }
        }
        .right_icon {
            position: absolute;
            top: 40%;
            right: 48px;
            width: 32px;
            height: 32px;
        }
    }

    .list-item:last-child {
        border-bottom: none;
    }
}
::v-deep .filter .el-form--inline {
    padding-left: 1.5rem;
    padding-right: 0.5rem;
}
::v-deep .filter .el-form-item {
    width: calc((100% - 2rem)/3)
}
</style>
